import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { Fade } from "react-reveal";

import footer4 from "./footer4.svg";
import tile from "./tile.svg";

import * as styles from "./contactcareers.module.scss";

const ContactCareers = ({
  component: { id, image, heading, phoneNumber, address, buttonLabel },
}) => {
  const hideStyle = {
    display: "none",
  };
  const {
    settingsJson: { footerData },
  } = useStaticQuery(graphql`
    {
      settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
        footerData {
          copyright {
            link
            text
          }
          privacyPolicy {
            link
            text
          }
          social {
            icon
            link
          }
        }
      }
    }
  `);
  // const [counter, setCounter] = useState(1);
  // const [idCounter, setIdCounter] = useState(5);
  // const handleClick = () => {
  //   setCounter(counter + 1);
  //   // setIdCounter(idCounter + 5);
  // };
  const [showHide, setShowHide] = useState("");
  const handleShowHide = (event) => {
    const getRole = event.target.value;
    console.log(getRole);
    setShowHide(getRole);
  };

  return (
    <section id={id} className={styles.section}>
      <Fade left>
        <div
          className={styles.image + " background-cover-center"}
          style={{ backgroundImage: `url(${image})` }}
        />
      </Fade>
      <Fade up delay={500}>
        <div className={styles.contactInfo}>
          <h2 className={styles.heading}>{heading}</h2>
          <p className={styles.address}>
            {address.split("\n").map((line, i) => (
              <span key={i} className={styles.line}>
                {line}
              </span>
            ))}
          </p>
        </div>
      </Fade>
      <Fade right delay={1000}>
        <div className={styles.formContainer}>
          <form
            className={styles.form + " pageclip-form"}
            action="https://send.pageclip.co/sMwoOCkPTfanAJXicIsr2TdLTmXhEcMV/Career_Contact_Form"
            method="POST"
          >
            <input
              type="hidden"
              name="subject"
              value="Gen4 Careers Contact - User Submit"
            />
            <div className={styles.fieldset}>
              <select
                onChange={(e) => handleShowHide(e)}
                className={styles.field}
                placeholder="Role"
                id="1"
                name="Role"
                required
              >
                <option value="">Select Role</option>
                <option value="dentist">Dentist</option>
                <option value="hygienist">Hygienist</option>
                <option value="dental assistant">Dental Assistant</option>
                <option value="office admin">Office Admin</option>
              </select>
              <input
                className={styles.field}
                type="text"
                placeholder="First Name, Last Name*"
                id="1"
                name="Full Name"
                required
              />

              <input
                className={styles.field}
                type="phone"
                placeholder="Phone*"
                id="2"
                name="Phone Number"
                required
              />
              <input
                className={styles.field}
                type="email"
                placeholder="Email*"
                id="3"
                name="Email Address"
                required
              />
              <input
                className={styles.field}
                type="text"
                placeholder="Zip Code*"
                id="4"
                name="Zip Code"
                required
              />
            </div>

            {showHide === "dentist" && (
              <div className={styles.fieldset}>
                <p className={styles.fieldLabel}>
                  What States are you moving to?*
                </p>
                <input
                  className={styles.field}
                  type="text"
                  placeholder="States"
                  id="5"
                  name="States"
                  required
                />
                <p className={styles.fieldLabel}>
                  How much would you like to earn per year within the next 5
                  years?*
                </p>
                <input
                  className={styles.field}
                  type="text"
                  placeholder="$XXX,XXX"
                  id="6"
                  name="5 Year Goal Income"
                  required
                />
                <p className={styles.fieldLabel}>
                  What type of dentistry would you like to specialize in?*
                </p>
                <input
                  className={styles.field}
                  type="text"
                  placeholder="Specialization"
                  id="7"
                  name="Dentistry Specialty"
                  required
                />
                <p className={styles.fieldLabel}>
                  Do you want to have ownership in a practice in the next 5
                  years?*
                </p>
                <input
                  className={styles.field}
                  type="text"
                  placeholder="Y/N"
                  id="8"
                  name="Ownership Goals"
                  required
                />
              </div>
            )}

            <button
              type="submit"
              className={styles.button + " pageclip-form__submit"}
            >
              <div className={styles.line} />
              <Fade right cascade delay={1000}>
                <p className={styles.label}>{buttonLabel}</p>
              </Fade>
            </button>
          </form>
        </div>
      </Fade>
      <Fade bottom delay={1500}>
        <div className={styles.footerContainer}>
          <footer
            className={styles.footer}
            style={{ backgroundImage: `url(${tile})` }}
          >
            <Fade up cascade>
              <div className={styles.copyrightContainer}>
                {footerData.copyright.link ? (
                  <a
                    className={styles.copyright}
                    href={footerData.copyright.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {footerData.copyright.text}
                  </a>
                ) : (
                  <p className={styles.copyright}>
                    {footerData.copyright.text}
                  </p>
                )}
                <span className={styles.span}> | </span>
                <a
                  className={styles.branding}
                  href="http://fireupyourbrand.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Website by Station8
                </a>
              </div>
            </Fade>
            <Fade up delay={500}>
              <div className={styles.privacyPolicyContainer}>
                <Link
                  className={styles.privacyPolicy}
                  to={footerData.privacyPolicy.link}
                >
                  {footerData.privacyPolicy.text}
                </Link>
              </div>
            </Fade>
            {footerData.social && footerData.social.length ? (
              <div className={styles.socialLinks}>
                {footerData.social.map((account, i) => (
                  <Fade key={i} up delay={500 * i}>
                    <a
                      href={account.link}
                      className={styles.socialLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className={styles.icon + ` background-contain-center`}
                        style={{ backgroundImage: `url(${account.icon})` }}
                      />
                    </a>
                  </Fade>
                ))}
              </div>
            ) : null}
            <Fade right delay={1000}>
              <div
                className={styles.footer4}
                style={{ backgroundImage: `url(${footer4})` }}
              />
            </Fade>
          </footer>
        </div>
      </Fade>
    </section>
  );
};

export default ContactCareers;

export const query = graphql`
  fragment ContactCareers on PagesJson {
    components {
      image
      heading
      phoneNumber
      address
      buttonLabel
    }
  }
`;
