import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './columns.module.scss'

const Columns = ({ component: { columns, id, label } }) => (
  <section id={id} className={styles.section}>
    <Fade down>
      <h2 className={styles.label}>{label}</h2>
    </Fade>
    <div className={styles.columns}>
      {columns.map((column, i) => (
        <Fade key={i} cascade left delay={(i * 500) + 500}>
          <div className={styles.column}>
            <div className={styles.image + ' background-contain-center'} style={{ backgroundImage: `url(${column.image})` }} />
            <h2 className={styles.heading}>{column.heading}</h2>
            <p className={styles.text}>{column.text}</p>
          </div>
        </Fade>
      ))}
    </div>
  </section>
)

export default Columns

export const query = graphql`
  fragment Columns on PagesJson {
    components {
      label
      columns {
        image
        heading
        text
      }
    }
  }
`
