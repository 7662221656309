import React from 'react'
import { graphql, Link } from 'gatsby'
import ReactPlayer from 'react-player'
import { Fade } from 'react-reveal'

import * as styles from './alternatingList.module.scss'

const AlternatingList = ({ component: { list } }) => (
    <section className={styles.section}>
      {list.map((listItem, i) => {
        return (
          <Fade up key={i}>
          <div className={styles.listItem}>
            <div className={styles.media}>
              {listItem.media_type === 'Video' && listItem.video_url ? (
                <div className={styles.videoContainer}>
                <ReactPlayer width='100%' height='100%' controls className={styles.reactPlayer} url={listItem.video_url} />
                </div>
              ) : <div className={styles.image} style={{ backgroundImage: `url(${listItem.image})`}} />}
            </div>
            <div className={styles.content}>
              <div className={styles.textContainer}>
                <h2 className={styles.heading}>{listItem.heading}</h2>
                <p className={styles.text}>{listItem.text}</p>
                {listItem.buttons && listItem.buttons.length ? (
                  <div className={styles.buttons}>
                    {listItem.buttons.map((button, i) => button.display ? (
              <Link key={i} className={styles.button} to={button.link}>
                <div className={styles.line} />
                  <p className={styles.label}>{button.label}</p>
              </Link>)
            : null
                      )
                    }
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          </Fade>
        )
      })}
    </section>
  )

export const query = graphql`
  fragment AlternatingList on PagesJson {
    components {
      list {
        heading
        text
        media_type
        image
        video_url
        buttons {
          display
          label
          link
        }
      }
    }
  }
`

export default AlternatingList
