import React, { useEffect, useState } from 'react'

import { Links, Logo } from './components'

import * as styles from './header.module.scss'

const Header = ({ openForm }) => {
  const [scrolled, setScrolled] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    const scrolled = () => setScrolled(window.scrollY >= 50)
    window.addEventListener('scroll', scrolled)
  }, [])

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header id='site-header' className={styles.header + `${scrolled ? ` ${styles.scrolled}` : ''}` + `${menuOpen ? ` ${styles.open}` : ''}`}>
      <Logo scrolled={scrolled} />
      <Links scrolled={scrolled} openForm={openForm} menuOpen={menuOpen} handleToggleMenu={handleToggleMenu} />
      <div className={styles.menuButton} onClick={handleToggleMenu}>
        <div className={styles.labelContainer}>
          <p className={styles.label}>{menuOpen ? 'CLOSE' : 'MENU'}</p>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.button} />
        </div>
      </div>
    </header>
  )
}

export default Header
