// extracted by mini-css-extract-plugin
export var section = "awardsCarousel-module--section--3rzOb";
export var thumbnailsContainer = "awardsCarousel-module--thumbnails-container--1b2SC";
export var label = "awardsCarousel-module--label--R1B_3";
export var thumbnails = "awardsCarousel-module--thumbnails--27TPD";
export var member = "awardsCarousel-module--member--BJTqW";
export var profile = "awardsCarousel-module--profile--wT1xZ";
export var images = "awardsCarousel-module--images--qKVZk";
export var candid = "awardsCarousel-module--candid--H2df8";
export var image = "awardsCarousel-module--image--1mgve";
export var name = "awardsCarousel-module--name--3NCzK";
export var title = "awardsCarousel-module--title--s23eS";
export var bio = "awardsCarousel-module--bio--19D99";
export var paragraph = "awardsCarousel-module--paragraph--2tdgV";
export var arrows = "awardsCarousel-module--arrows--2c93T";
export var left = "awardsCarousel-module--left--wiDLO";
export var right = "awardsCarousel-module--right--3Kb35";