import React from 'react'
import { graphql } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'
import { Fade } from 'react-reveal'

import * as styles from './imageWithHeadingAndText.module.scss'

const ImageWithHeadingAndText = ({ component: { boldText, id, image, label, text } }) => (
  <section id={id} className={styles.section}>
    <Fade left>
      <div className={styles.image + ' background-cover-center'} style={{ backgroundImage: `url(${image})` }} />
    </Fade>
    {label
      ? (
        <div className={styles.labelContainer}>
          <Parallax x={[-50, 150]}>
            <h2 className={styles.label}>{label}</h2>
          </Parallax>
        </div>
      )
      : null}
    <Fade left>
      <div className={styles.arrowContainer}>
        <Parallax x={[-100, 200]}>
          <div className={styles.arrow + ' background-contain-center'} />
        </Parallax>
      </div>
    </Fade>
    <Fade right cascade>
      <div className={styles.content}>
        <div className={styles.heading}>
          {boldText.split('\n').map((paragraph, i) => (
            <p key={i} className={styles.paragraph}>{paragraph}</p>
          ))}
        </div>
        <div className={styles.text}>
          {text.split('\n').map((paragraph, i) => (
            <p key={i} className={styles.paragraph}>{paragraph}</p>
          ))}
        </div>
      </div>
    </Fade>
  </section>
)

export default ImageWithHeadingAndText

export const query = graphql`
  fragment ImageWithHeadingAndText on PagesJson {
    components {
      label
      image
      boldText
      text
    }
  }
`
