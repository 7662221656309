import React from "react";
import { graphql, Link } from "gatsby";
import { Fade } from "react-reveal";
import { useState } from "react";

import * as styles from "./podcast.module.scss";

const Podcast = ({ component: { label } }) => {
  const [userInput, setUserInput] = useState("");
  const [isPromptAnswered, setIsPromptAnswered] = useState(false);
  const secretString = "Gen4Podcast"; // Replace with your secret string

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    if (userInput === secretString) {
      setIsPromptAnswered(true);
    } else {
      alert("Incorrect password. Try again.");
    }
  };

  return (
    <div id={styles.podcastPage}>
      {!isPromptAnswered ? (
        <section className={styles.podcastContainer} id="podcast">
          <Fade up delay={500}>
            <div className={styles.contentBox}>
              <p>Please enter the password to view Podcast:</p>
              <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                className={styles.podcastPassword}
              />
              <button onClick={handleSubmit} className={styles.podcastButton}>
                Submit
              </button>
            </div>
          </Fade>
        </section>
      ) : (
        <section className={styles.podcastContainer} id="podcast">
          <Fade up delay={500}>
            <div className={styles.contentBox}>
              <div className={styles.imageTitleContainer}>
                <img
                  src="/dental-4cast-art-square-min.jpg"
                  alt="Image"
                  className={styles.podcastShowImg}
                />
                <div className={styles.titleContainer}>
                  <h1>The Dental 4Cast</h1>
                  <p className={styles.subtitle}>
                    Dr. Dustin Mayrand and Dr. Craig Packer
                  </p>
                  {/* <a
                    href="http://feeds.libsyn.com/458220/rss"
                    title="rss feed"
                    target="_blank"
                    className={styles.icons}
                  >
                    <img src="/rss-icon.svg" alt="rss" />
                  </a>
                  <a
                    href="https://podcasts.apple.com/podcast/id1692994057"
                    title="apple podcast"
                    target="_blank"
                    className={styles.icons}
                  >
                    <img src="/apple-icon.svg" alt="apple" />
                  </a>
                  <a
                    href="https://open.spotify.com/show/2hhN2wT3frm2S4LpBgdWF9"
                    title="spotify podcast"
                    target="_blank"
                    className={styles.icons}
                  >
                    <img src="/spotify-icon.svg" alt="spotify" />
                  </a>
                  <a
                    href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5saWJzeW4uY29tLzQ1ODIyMC9yc3M="
                    title="google podcast"
                    target="_blank"
                    className={styles.icons}
                  >
                    <img src="/google-podcast-icon.svg" alt="google" />
                  </a> */}
                </div>
              </div>

              <p>
                Dr. Mayrand and Dr. Packer host their new podcast, The Dental
                4Cast, where they and their guests provide expert insights on
                all things dental, from the gritty to the gory and sometimes
                downright gross stuff. Join them for a cheeky good time as they
                explore the fascinating world of dentistry.
              </p>
              <iframe title="Embed Player" className={styles.podcastIframe} src="https://play.libsyn.com/embed/destination/id/3860532/height/532/theme/modern/size/large/thumbnail/yes/custom-color/a9d7e6/playlist-height/320/direction/backward" height="532" width="100%" scrolling="no" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe>
            </div>
          </Fade>
        </section>
      )}
    </div>
  );
};

export default Podcast;

export const query = graphql`
  fragment Podcast on PagesJson {
    components {
      label
    }
  }
`;
