// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "partners-module--background-cover-center--1C8E0";
export var backgroundCoverTop = "partners-module--background-cover-top--3qBb_";
export var backgroundContainTop = "partners-module--background-contain-top--19UeT";
export var backgroundContainCenter = "partners-module--background-contain-center--2rbRD";
export var backgroundContainLeft = "partners-module--background-contain-left--2WEo_";
export var section = "partners-module--section--3UC32";
export var heading = "partners-module--heading--3N5Lx";
export var partners = "partners-module--partners--1R7tS";
export var partnerContainer = "partners-module--partner-container--2jB8c";
export var partner = "partners-module--partner--3RwC1";
export var name = "partners-module--name--3ujhV";
export var arrowContainer = "partners-module--arrow-container--3B2Aq";
export var arrow = "partners-module--arrow--3Q_u6";