import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import * as styles from './logo.module.scss'

const Logo = ({ scrolled }) => {
  const [logo, setLogo] = useState(undefined)

  const { allSettingsJson } = useStaticQuery(graphql`{
    allSettingsJson(filter: {fields: {slug: {eq: "/settings/settings/"}}}) {
      nodes {
        header {
          logo
        }
      }
    }
  }`)

  useEffect(() => {
    setLogo(allSettingsJson.nodes[0].header.logo)
  }, [allSettingsJson])

  return (
    <section className={styles.section + `${scrolled ? ` ${styles.scrolled}` : ''}`}>
      <Link className={styles.logo} to='/' style={{ background: `url(${logo})` }} />
    </section>
  )
}

export default Logo
