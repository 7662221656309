import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'
import parse from 'html-react-parser'

import * as styles from './overview.module.scss'

const Overview = ({ component: { content, heading, id } }) => (
  <section id={id} className={styles.section}>
    <div className={styles.body}>
      {heading && (
        <Fade left cascade>
          <p className={styles.heading}>{parse(heading)}</p>
        </Fade>
      )}
      {content && <Fade left><p className={styles.content}>{content}</p></Fade>}
    </div>
  </section>
)

export default Overview

export const query = graphql`
  fragment Overview on PagesJson {
    components {
      label
      heading
      content
    }
  }
`
