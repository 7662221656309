// extracted by mini-css-extract-plugin
export var section = "awardsCarousel24-module--section--2HBvn";
export var thumbnailsContainer = "awardsCarousel24-module--thumbnails-container--1o9yq";
export var label = "awardsCarousel24-module--label--3qSde";
export var thumbnails = "awardsCarousel24-module--thumbnails--3HxrC";
export var member = "awardsCarousel24-module--member--2iPCs";
export var profile = "awardsCarousel24-module--profile--2Te1l";
export var images = "awardsCarousel24-module--images--3OGqy";
export var candid = "awardsCarousel24-module--candid--1zehC";
export var image = "awardsCarousel24-module--image--T9yJ_";
export var name = "awardsCarousel24-module--name--1nmbf";
export var title = "awardsCarousel24-module--title--1Ru68";
export var bio = "awardsCarousel24-module--bio--7M9h7";
export var paragraph = "awardsCarousel24-module--paragraph--2E_p2";
export var arrows = "awardsCarousel24-module--arrows--1CUvj";
export var left = "awardsCarousel24-module--left--3FFxV";
export var right = "awardsCarousel24-module--right--2o2qX";