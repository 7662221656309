import React from "react";
import { graphql, Link } from "gatsby";
import { Fade } from "react-reveal";
import { useState } from "react";

import * as styles from "./gen4Awards.module.scss";

const Gen4Awards = ({ component: { label } }) => {
  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);
  const [showContent3, setShowContent3] = useState(false);
  const [showContent4, setShowContent4] = useState(false);

  const toggleContent = (paragraphNumber) => {
    switch (paragraphNumber) {
      case 1:
        setShowContent1(!showContent1);
        break;
      case 2:
        setShowContent2(!showContent2);
        break;
      case 3:
        setShowContent3(!showContent3);
        break;
      case 4:
        setShowContent4(!showContent4);
        break;
      // Add more cases as needed for additional paragraphs
      default:
        break;
    }
  };

  return (
    <div id={styles.awards}>
      <section className={styles.awardsContainer}>
        <Fade up delay={500}>
          <h2>Award-Winning Offices</h2>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/location-cassity-min.jpg"
                alt="photo of cassity implants"
              />
            </div>
            <div className={styles.col9}>
              <h2>Office of the Year - Clinical Outcomes</h2>
              <h3>Cassity Implants</h3>

              <p>
                Cassity Implant's remarkable achievement in delivering superior
                clinical outcomes has deservedly earned them the Gen4 LeGEND
                Award for Office of the Clinical Outcomes. This recognition is a
                testament to their unwavering commitment to providing the
                highest standard of dental care.{" "}
                {!showContent1 && (
                  <button onClick={() => toggleContent(1)}>Read More</button>
                )}
              </p>

              {showContent1 && (
                <div>
                  <p>
                    The team at Cassity Implants has consistently demonstrated
                    excellence in patient treatment, utilizing the latest in
                    dental technology and techniques to ensure effective and
                    long-lasting results. Their approach is deeply rooted in
                    evidence-based practices, continuous learning, and a
                    proactive stance on patient education, which collectively
                    contribute to outstanding clinical outcomes. The dedication
                    of the dental professionals at Cassity Implants to staying
                    abreast of the latest developments in dental research and
                    their commitment to ongoing professional development has
                    significantly enhanced their ability to provide top-tier
                    dental services. This, combined with their meticulous
                    attention to patient-specific needs and a personalized care
                    approach, has led to impressive patient satisfaction rates
                    and improved oral health in their community.
                  </p>
                  <p>
                    The success of Cassity Implants in achieving remarkable
                    clinical results is not just a reflection of their technical
                    skills, but also of their holistic approach to patient care,
                    making them a deserving winner of the Office of the Clinical
                    Outcomes award.
                  </p>
                </div>
              )}
            </div>
          </div>
        </Fade>

        <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/location-little-smiles-2.jpg"
                alt="photo of little smiles office"
              />
            </div>
            <div className={styles.col9}>
              <h2>Office of the Year - Create a Special Place</h2>

              <h3>Little Smiles</h3>
              <p>
                Little Smile's extraordinary achievement in creating an
                exceptional workplace has rightfully earned it the Gen4 LeGEND
                Award for Office of the Year. This distinction stems from their
                remarkable employee retention rates and the vibrant, positive
                culture they have cultivated.{" "}
                {!showContent2 && (
                  <button onClick={() => toggleContent(2)}>Read More</button>
                )}
              </p>
              <p>
                {showContent2 && (
                  <div>
                    <p>
                      The team at Little Smiles has consistently demonstrated a
                      deep commitment to not only providing outstanding dental
                      care but also to fostering a supportive and inclusive work
                      environment. Their approach to employee well-being and
                      professional development is exemplary, ensuring that each
                      team member feels valued, respected, and motivated. The
                      office environment is characterized by open communication,
                      mutual respect, and a strong sense of community, which are
                      key factors in their high retention rates.
                    </p>
                    <p>
                      The leadership at Little Smiles actively works to
                      recognize and reward the hard work and contributions of
                      their staff, creating a culture of appreciation and
                      growth. This environment of encouragement and support
                      empowers employees to take initiative and contribute their
                      best work. The team's camaraderie, combined with their
                      dedication to excellence in patient care, makes Little
                      Smiles not just a workplace but a special place where
                      professionals thrive and grow. Their achievement in
                      building such a positive and nurturing work environment is
                      what makes Beaumont a deserving recipient of the Office of
                      the Year for Creating a Special Place to Work.
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </Fade>

        <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/location-beaumont-min.jpg"
                alt="photo of beaumont family dentistry office"
              />
            </div>
            <div className={styles.col9}>
              <h2>Office of the Year - Growth</h2>

              <h3>Beaumont Family Dentistry - Leestown</h3>
              <p>
                Beaumont's impressive trajectory in growth and development has
                rightfully earned them the Gen4 LeGEND Award for Office of the
                Year for Growth. This prestigious recognition reflects their
                extraordinary success in expanding their patient base, enhancing
                service offerings, and increasing their overall business
                footprint.{" "}
                {!showContent3 && (
                  <button onClick={() => toggleContent(3)}>Read More</button>
                )}
              </p>
              {showContent3 && (
                <div>
                  <p>
                    The team atBeaumont has exhibited a remarkable ability to
                    not only attract new patients but also retain existing ones
                    by providing exceptional dental care and creating a
                    welcoming, trust-inspiring environment.
                  </p>
                  <p>
                    Their strategic marketing efforts, coupled with a strong
                    focus on community engagement, have played a pivotal role in
                    their growth. Furthermore, the office has embraced
                    innovation, incorporating advanced dental technologies and
                    broadening their range of services to meet diverse patient
                    needs. This approach has not only improved patient outcomes
                    and satisfaction but has also positioned Beaumont as a
                    leader in the dental field. The leadership's vision for
                    growth, supported by the dedicated efforts of their team,
                    has led to notable increases in both revenue and reputation.
                  </p>
                  <p>
                    Their success story is a testament to the power of combining
                    excellent patient care with strategic business acumen,
                    making Beaumont an exemplary recipient of the Office of the
                    Year for Growth award.
                  </p>
                </div>
              )}
            </div>
          </div>
        </Fade>

        <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/location-gentling-min.jpg"
                alt="photo of cassity implants"
              />
            </div>

            <div className={styles.col9}>
              <h2>Office of the Year - Ops Excellence</h2>
              <h3 className={styles.doctorName}>Gentling Dental Care</h3>

              <p>
                Gentling's exceptional performance in operational excellence has
                earned it the prestigious Gen4 LeGEND Award for Office of the
                Year. This accolade is a testament to their outstanding
                efficiency, seamless patient care processes, and exemplary
                administrative management.{" "}
                {!showContent4 && (
                  <button onClick={() => toggleContent(4)}>Read More</button>
                )}
              </p>
              <p>
                {showContent4 && (
                  <div>
                    <p>
                      The team at Gentling Dental Care has consistently
                      demonstrated a commitment to optimizing every aspect of
                      their operations, from streamlined appointment scheduling
                      and effective patient communication to advanced technology
                      utilization and meticulous record-keeping.
                    </p>
                    <p>
                      Their focus on operational excellence has not only
                      enhanced the patient experience but also improved the
                      workflow for the staff, creating a harmonious and
                      productive work environment. The leadership at Gentling
                      Dental Care has been instrumental in implementing
                      innovative strategies and best practices that drive
                      efficiency and quality care. Their ability to adapt to
                      changing circumstances, while maintaining high standards
                      of service, sets them apart in the dental industry. The
                      team's collaborative efforts, attention to detail, and
                      dedication to continuous improvement have resulted in
                      remarkable operational success, making Gentling Dental
                      Care a model of excellence in dental office management.
                    </p>
                    <p>
                      Their achievement in maintaining such high operational
                      standards makes them a deserving recipient of the Office
                      of the Year for Operational Excellence award, highlighting
                      their role as a leader in setting benchmarks for the
                      industry.
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </Fade>

        {/* <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img src="/team-logan-c.jpg" alt="photo of cassity implants" />
            </div>

            <div className={styles.col9}>
              <h2>Pathway to Partnership Doctor of The Year</h2>
              <h3 className={styles.doctorName}>Dr. Logan Clements</h3>
              <h4>Community Shores Dental | Michigan</h4>
              <p>
                Gentling's exceptional performance in operational excellence has
                earned it the prestigious Gen4 LeGEND Award for Office of the
                Year. This accolade is a testament to their outstanding
                efficiency, seamless patient care processes, and exemplary
                administrative management. <a href="#">Read More</a>
              </p>
            </div>
          </div>
        </Fade> */}
      </section>
    </div>
  );
};

export default Gen4Awards;

export const query = graphql`
  fragment Gen4Awards on PagesJson {
    components {
      label
    }
  }
`;
