// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "heroWithLogoAwardsArchive-module--background-cover-center--dLjgq";
export var backgroundCoverTop = "heroWithLogoAwardsArchive-module--background-cover-top--1zCVP";
export var backgroundContainTop = "heroWithLogoAwardsArchive-module--background-contain-top--1WCmP";
export var backgroundContainCenter = "heroWithLogoAwardsArchive-module--background-contain-center--3uB0L";
export var backgroundContainLeft = "heroWithLogoAwardsArchive-module--background-contain-left--2_PZ3";
export var section = "heroWithLogoAwardsArchive-module--section--CWmCC";
export var background = "heroWithLogoAwardsArchive-module--background--bVTbt";
export var content = "heroWithLogoAwardsArchive-module--content--3YIke";
export var fill = "heroWithLogoAwardsArchive-module--fill--3MZXT";
export var logo = "heroWithLogoAwardsArchive-module--logo--2FfDo";
export var yearText = "heroWithLogoAwardsArchive-module--year-text--2hgW9";
export var textContainer = "heroWithLogoAwardsArchive-module--text-container--1ODLp";
export var textDiv = "heroWithLogoAwardsArchive-module--text-div--1o_yH";
export var button = "heroWithLogoAwardsArchive-module--button--1PuP3";
export var bannerText = "heroWithLogoAwardsArchive-module--banner-text--15Dat";
export var line = "heroWithLogoAwardsArchive-module--line--26VYl";
export var countdownContainer = "heroWithLogoAwardsArchive-module--countdown-container--2rJGv";
export var countdownLabel = "heroWithLogoAwardsArchive-module--countdown-label--fJ9JO";
export var countdownTo = "heroWithLogoAwardsArchive-module--countdown-to--3Jywy";