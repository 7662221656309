import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { Fade } from "react-reveal";

import footer4 from "./footer4.svg";
import tile from "./tile.svg";

import * as styles from "./contact.module.scss";

const Contact = ({
  component: { id, image, heading, phoneNumber, address, buttonLabel },
}) => {
  const hideStyle = {
    display: "none",
  };
  const {
    settingsJson: { footerData },
  } = useStaticQuery(graphql`
    {
      settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
        footerData {
          copyright {
            link
            text
          }
          privacyPolicy {
            link
            text
          }
          social {
            icon
            link
          }
        }
      }
    }
  `);

  const [showHide, setShowHide] = useState("");
  const handleShowHide = (event) => {
    const getRole = event.target.value;
    console.log(getRole);
    setShowHide(getRole);
  };

  return (
    <section id={id} className={styles.contactSection}>
      <Fade left>
        <div
          className={styles.image + " background-cover-center"}
          style={{ backgroundImage: `url(${image})` }}
        />
      </Fade>
      <Fade up delay={500}>
        <div className={styles.contactInfo}>
          <h2 className={styles.heading}>{heading}</h2>
          <p className={styles.address}>
            {address.split("\n").map((line, i) => (
              <span key={i} className={styles.line}>
                {line}
              </span>
            ))}
          </p>
        </div>
      </Fade>
      <Fade right delay={1000}>
        <div className={styles.formContainer}>
          <form
            className={styles.form + " pageclip-form"}
            action="https://send.pageclip.co/gg1vCfj62zYcHiVuZRRpIPWVT84NYtDh/contact-form"
            method="POST"
          >
            <input
              className={styles.field}
              type="text"
              placeholder="First Name, Last Name*"
              id="3"
              name="name"
              required
            />
            <input
              className={styles.field}
              type="phone"
              placeholder="Phone*"
              id="4"
              name="phone"
              required
            />
            <input
              className={styles.field}
              type="email"
              placeholder="Email*"
              id="5"
              name="email"
              required
            />
            <input
              className={styles.field}
              type="text"
              placeholder="State*"
              id="state"
              name="state"
              required
            />
            <fieldset className={styles.fieldset}>
              <select
                onChange={(e) => handleShowHide(e)}
                className={styles.field}
                placeholder="Do you own a practice?"
                id="1"
                name="Do you own a practice"
                required
              >
                <option value="">Do you own a practice?</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </fieldset>
            {showHide === "yes" && (
              <div className={styles.fieldset}>
                <fieldset className={styles.fieldset}>
                    <label htmlFor="company name" className={styles.label}>
                      Company Name*
                    </label>
                    <input
                      className={styles.field}
                      placeholder=""
                      id="6"
                      name="company name"
                      type="text"
                      required
                    />
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <label htmlFor="revenue" className={styles.label}>
                    Approximate Annual Revenue
                  </label>
                  <input
                    className={styles.field + " " + styles.number}
                    placeholder=""
                    id="10"
                    name="revenue"
                    type="number"
                    min={0}
                    
                  />
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <label htmlFor="operatories" className={styles.label}>
                    Number of Operatories
                  </label>
                  <input
                    className={styles.field + " " + styles.number}
                    placeholder=""
                    id="10"
                    name="operatories"
                    type="number"
                    min={0}
                  />
                </fieldset>

                <fieldset className={styles.fieldset}>
                    <label htmlFor="specialty" className={styles.label}>
                      Does your practice focus on any type of specialty dentistry?
                    </label>
                    <input
                      className={styles.field}
                      placeholder=""
                      id="6"
                      name="specialty"
                      type="text"
                      
                    />
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <label htmlFor="locations" className={styles.label}>
                    How many practice locations or centers do you have?
                  </label>
                  <input
                    className={styles.field + " " + styles.number}
                    placeholder=""
                    id="8"
                    name="locations"
                    type="number"
                    min={0}
                    
                  />
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <label htmlFor="doctors" className={styles.label}>
                    How many doctors do you have working at your practice(s)?
                  </label>
                  <input
                    className={styles.field + " " + styles.number}
                    placeholder=""
                    id="8"
                    name="doctors"
                    type="number"
                    min={0}
                    
                  />
                </fieldset>
              </div>
            )}
            
            <fieldset className={styles.fieldset}>
              <label htmlFor="message" className={styles.label}>
                Message...
              </label>
              <textarea
                id="7"
                name="message"
                className={styles.textarea}
                rows={4}
              />
            </fieldset>
            <button
              type="submit"
              className={styles.button + " pageclip-form__submit"}
            >
              <div className={styles.line} />
              {/* <Fade right cascade delay={1000}> */}
                <p className={styles.label}>{buttonLabel}</p>
              {/* </Fade> */}
            </button>
          </form>
        </div>
      </Fade>
      <Fade bottom delay={1500}>
        <div className={styles.footerContainer}>
          <footer
            className={styles.footer}
            style={{ backgroundImage: `url(${tile})` }}
          >
            <Fade up cascade>
              <div className={styles.copyrightContainer}>
                {footerData.copyright.link ? (
                  <a
                    className={styles.copyright}
                    href={footerData.copyright.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {footerData.copyright.text}
                  </a>
                ) : (
                  <p className={styles.copyright}>
                    {footerData.copyright.text}
                  </p>
                )}
              </div>
            </Fade>
            <Fade up delay={500}>
              <div className={styles.privacyPolicyContainer}>
                <Link
                  className={styles.privacyPolicy}
                  to={footerData.privacyPolicy.link}
                >
                  {footerData.privacyPolicy.text}
                </Link>
              </div>
            </Fade>
            {footerData.social && footerData.social.length ? (
              <div className={styles.socialLinks}>
                {footerData.social.map((account, i) => (
                  <Fade key={i} up delay={500 * i}>
                    <a
                      href={account.link}
                      className={styles.socialLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className={styles.icon + ` background-contain-center`}
                        style={{ backgroundImage: `url(${account.icon})` }}
                      />
                    </a>
                  </Fade>
                ))}
              </div>
            ) : null}
            <Fade right delay={1000}>
              <div
                className={styles.footer4}
                style={{ backgroundImage: `url(${footer4})` }}
              />
            </Fade>
          </footer>
        </div>
      </Fade>
    </section>
  );
};

export default Contact;

export const query = graphql`
  fragment Contact on PagesJson {
    components {
      image
      heading
      phoneNumber
      address
      buttonLabel
    }
  }
`;
