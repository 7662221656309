import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import * as styles from './logoCarousel.module.scss'

const LogoCarousel = ({ component: { id, heading, logos, text } }) => {
    const [index, setIndex] = useState(undefined)
    const [logosLoop, setLogosLoop] = useState([])
    const [indexes, setIndexes] = useState(undefined)

    const handleSetIndexes = () => {
      const newIndex = logosLoop.length - 1 === index ? 0 : index + 1
      setIndexes(indexes && indexes.length ? [...indexes.slice(-5), newIndex] : [newIndex])
      setTimeout(() => {
        setIndex(newIndex)
      }, 4000);
    }
    
    useEffect(() => {
      if (logosLoop.length && index !== undefined) {
      handleSetIndexes()
      }
    }, [index, logosLoop])

    useEffect(() => {
      if (logosLoop.length) {
        setIndex(0)
      }
    }, [logosLoop])

    useEffect(() => {
      console.log(logos.length)
      const repeatLoop = () => {
        const newLogos = []
        while (newLogos.length < 7) {
          newLogos.push(...logos)
        }
        return newLogos
      }
      setLogosLoop(logos.length > 6 ? logos : repeatLoop())
    }, [logos])

  return (
    <section id={id} className={styles.section}>
      <h2 className={styles.heading}>{heading}</h2>
      {text ? (
        <div className={styles.text}>
          {text ? parse(text) : null}
        </div>
      ) : null}
      <div className={styles.container}>
          <div className={styles.overlay} />
            <div className={styles.logos}>
              {logosLoop.map((logo, i) => <div key={i} className={styles.logo + `${indexes && indexes.includes(i) ? ` ${styles.active}` : ''}`} style={{ backgroundImage: `url(${logo.image})`}} />)}
            </div>
      </div>
    </section>
  )
}

export default LogoCarousel

export const query = graphql`
  fragment LogoCarousel on PagesJson {
    components {
      heading
      text
      logos {
        label
        image
      }
    }
  }
`
