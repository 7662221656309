import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './people.module.scss'

const People = ({ component: { id, label, people } }) => {
  const [active, setActive] = useState(0)
  const [candid, setCandid] = useState(false)

  // const handleNext = () => {
  //   setActive(active === staff.length - 1 ? 0 : active + 1)
  //   setCandid(false)
  // }

  // const handlePrevious = () => {
  //   setActive(active === 0 ? staff.length - 1 : active - 1)
  //   setCandid(false)
  // }

  const handleToggleCandid = () => {
    setCandid(!candid)
  }

  return (
    <section id={id} className={styles.section}>
      <Fade left>
        <div className={styles.thumbnailsContainer}>
          <h2 className={styles.label}>{label}</h2>
        <div className={styles.thumbnails}>
          {people.map((member, i) => {
            const handleClick = () => {
              setActive(i)
            }
            return (
              <div onClick={handleClick} key={i} className={styles.member + ' background-cover-center'} style={{ backgroundImage: `url(${member.image})`}} />
            )
          })}
        </div></div>
      </Fade>
      {/* <Fade down delay={500}>
        <div className={styles.arrows}>
          <div className={styles.left} onClick={handlePrevious} />
          <div className={styles.right} onClick={handleNext} />
        </div>
      </Fade> */}
      <Fade right delay={250}>
        <div className={styles.profile}>
          <Fade right delay={500}>
            <div className={styles.images}>
            {people[active].candid && people[active].candid !== people[active].image ? <div className={styles.candid + ' background-cover-center'} style={{ backgroundImage: `url(${people[active].candid})` }} /> : null}
            <div className={styles.image + ' background-cover-top'} style={{ backgroundImage: `url(${candid && people[active].candid ? people[active].candid : people[active].image})` }} onClick={handleToggleCandid} />
            </div>
          </Fade>
          <Fade right delay={750}>
            <h2 className={styles.name}>{people[active].name}</h2>
          </Fade>
          <Fade right delay={1000}>
            <h3 className={styles.title}>{people[active].title}</h3>
          </Fade>
          <Fade right>
          <div className={styles.bio}>
            {people[active].bio.split('\n').map((paragraph, i) => (
                <p key={i} className={styles.paragraph}>{paragraph}</p>
            ))}
          </div>
          </Fade>
        </div>
      </Fade>
    </section>
  )
}

export const query = graphql`
  fragment People on PagesJson {
    components {
      label
      people {
        name
        title
        image
        candid
        bio
      }
    }
  }
`

export default People
