import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { Fade } from "react-reveal";

import footer4 from "./footer4.svg";
import tile from "./tile.svg";

import * as styles from "./contactdrreferrals.module.scss";

const ContactDrReferrals = ({
  component: { id, image, heading, phoneNumber, address, buttonLabel },
}) => {
  const hideStyle = {
    display: "none",
  };
  const {
    settingsJson: { footerData },
  } = useStaticQuery(graphql`
    {
      settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
        footerData {
          copyright {
            link
            text
          }
          privacyPolicy {
            link
            text
          }
          social {
            icon
            link
          }
        }
      }
    }
  `);
  const [counter, setCounter] = useState(1);
  // const [idCounter, setIdCounter] = useState(5);
  const handleClick = () => {
    setCounter(counter + 1);
    // setIdCounter(idCounter + 5);
  };

  return (
    <section id={id} className={styles.section}>
      <Fade left>
        <div
          className={styles.image + " background-cover-center"}
          style={{ backgroundImage: `url(${image})` }}
        />
      </Fade>
      <Fade up delay={500}>
        <div className={styles.contactInfo}>
          <h2 className={styles.heading}>{heading}</h2>
          <p className={styles.address}>
            {address.split("\n").map((line, i) => (
              <span key={i} className={styles.line}>
                {line}
              </span>
            ))}
          </p>
        </div>
      </Fade>
      <Fade right delay={1000}>
        <div className={styles.formContainer}>
          <form
            className={styles.form + " pageclip-form"}
            action="https://send.pageclip.co/sMwoOCkPTfanAJXicIsr2TdLTmXhEcMV/Doctor-Referral"
            method="POST"
          >
            <input
              type="hidden"
              name="subject"
              value="Gen4 Doctor Referrals - User Submit"
            />
            <div className={styles.fieldset}>
              <p className={styles.formHeading}>Doctor Giving Referral:</p>
              <input
                className={styles.field}
                type="text"
                placeholder="First Name, Last Name*"
                id="3"
                name="Referring Dr[Name]"
                required
              />

              <input
                className={styles.field}
                type="phone"
                placeholder="Phone*"
                id="4"
                name="Referring Dr[Phone]"
                required
              />
              <input
                className={styles.field}
                type="email"
                placeholder="Email*"
                id="5"
                name="Referring Dr[Email]"
                required
              />
            </div>

            {Array.from(Array(counter)).map((c, index) => {
              return (
                <div className={styles.fieldset} key={c}>
                  <p className={styles.formHeading}>Doctor Being Referred:</p>
                  <input
                    className={styles.field}
                    type="text"
                    placeholder="First Name, Last Name"
                    name={`Referral ${index + 1}[Name]`}
                    id={`Name${index + 1}`}
                  />

                  <input
                    className={styles.field}
                    type="phone"
                    placeholder="Phone"
                    name={`Referral ${index + 1}[Phone]`}
                    id={`Phone${index + 1}`}
                  />
                  <input
                    className={styles.field}
                    type="email"
                    placeholder="Email"
                    name={`Referral ${index + 1}[Email]`}
                    id={`Email${index + 1}`}
                  />
                  <input
                    className={styles.field}
                    type="text"
                    placeholder="City"
                    name={`Referral ${index + 1}[City]`}
                    id={`City${index + 1}`}
                  />
                  <input
                    className={styles.field}
                    type="text"
                    placeholder="State"
                    name={`Referral ${index + 1}[State]`}
                    id={`State${index + 1}`}
                  />
                </div>
              );
            })}

            <p
              id="btn"
              className={[styles.fieldset, styles.addReferralBtn].join(" ")}
              onClick={handleClick}
            >
              + Add Another Referral
            </p>

            <div className={styles.fieldset}></div>

            <button
              type="submit"
              className={styles.button + " pageclip-form__submit"}
            >
              <div className={styles.line} />
              <Fade right cascade delay={1000}>
                <p className={styles.label}>{buttonLabel}</p>
              </Fade>
            </button>
          </form>
        </div>
      </Fade>
      <Fade bottom delay={1500}>
        <div className={styles.footerContainer}>
          <footer
            className={styles.footer}
            style={{ backgroundImage: `url(${tile})` }}
          >
            <Fade up cascade>
              <div className={styles.copyrightContainer}>
                {footerData.copyright.link ? (
                  <a
                    className={styles.copyright}
                    href={footerData.copyright.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {footerData.copyright.text}
                  </a>
                ) : (
                  <p className={styles.copyright}>
                    {footerData.copyright.text}
                  </p>
                )}
                <span className={styles.span}> | </span>
                <a
                  className={styles.branding}
                  href="http://fireupyourbrand.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Website by Station8
                </a>
              </div>
            </Fade>
            <Fade up delay={500}>
              <div className={styles.privacyPolicyContainer}>
                <Link
                  className={styles.privacyPolicy}
                  to={footerData.privacyPolicy.link}
                >
                  {footerData.privacyPolicy.text}
                </Link>
              </div>
            </Fade>
            {footerData.social && footerData.social.length ? (
              <div className={styles.socialLinks}>
                {footerData.social.map((account, i) => (
                  <Fade key={i} up delay={500 * i}>
                    <a
                      href={account.link}
                      className={styles.socialLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className={styles.icon + ` background-contain-center`}
                        style={{ backgroundImage: `url(${account.icon})` }}
                      />
                    </a>
                  </Fade>
                ))}
              </div>
            ) : null}
            <Fade right delay={1000}>
              <div
                className={styles.footer4}
                style={{ backgroundImage: `url(${footer4})` }}
              />
            </Fade>
          </footer>
        </div>
      </Fade>
    </section>
  );
};

export default ContactDrReferrals;

export const query = graphql`
  fragment ContactDrReferrals on PagesJson {
    components {
      image
      heading
      phoneNumber
      address
      buttonLabel
    }
  }
`;
