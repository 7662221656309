// extracted by mini-css-extract-plugin
export var section = "columnsWithSeparators-module--section--3Bi0f";
export var header = "columnsWithSeparators-module--header--1NLPk";
export var heading = "columnsWithSeparators-module--heading--1dYIR";
export var arrows = "columnsWithSeparators-module--arrows--26SUn";
export var left = "columnsWithSeparators-module--left--Upm6R";
export var right = "columnsWithSeparators-module--right--Ygo2R";
export var goals = "columnsWithSeparators-module--goals--2cGCs";
export var goal = "columnsWithSeparators-module--goal--3qVcP";
export var icon = "columnsWithSeparators-module--icon--2puWJ";
export var slashContainer = "columnsWithSeparators-module--slash-container--3Wwpp";
export var slash = "columnsWithSeparators-module--slash--20hi8";
export var copy = "columnsWithSeparators-module--copy--2CFRz";
export var line = "columnsWithSeparators-module--line--3doW2";