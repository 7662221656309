// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "gen4Awards24-module--background-cover-center--2qtXV";
export var backgroundCoverTop = "gen4Awards24-module--background-cover-top--3Mw7N";
export var backgroundContainTop = "gen4Awards24-module--background-contain-top--KAVpD";
export var backgroundContainCenter = "gen4Awards24-module--background-contain-center--3jqYS";
export var backgroundContainLeft = "gen4Awards24-module--background-contain-left--3ugBC";
export var awardsButton = "gen4Awards24-module--awardsButton--9Orhu";
export var awards = "gen4Awards24-module--awards--a4SN_";
export var awardRow = "gen4Awards24-module--award-row--3EtUM";
export var doctorName = "gen4Awards24-module--doctor-name--2txR0";
export var col3 = "gen4Awards24-module--col-3--1qwwx";
export var col9 = "gen4Awards24-module--col-9--YXTpS";
export var awardsContainer = "gen4Awards24-module--awards-container--wtEAr";