// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "gen4Awards-module--background-cover-center--7hjVn";
export var backgroundCoverTop = "gen4Awards-module--background-cover-top--18_Ea";
export var backgroundContainTop = "gen4Awards-module--background-contain-top--dSllm";
export var backgroundContainCenter = "gen4Awards-module--background-contain-center--2opjg";
export var backgroundContainLeft = "gen4Awards-module--background-contain-left--3HQTJ";
export var awardsButton = "gen4Awards-module--awardsButton--2yQ6f";
export var awards = "gen4Awards-module--awards--95b_r";
export var awardRow = "gen4Awards-module--award-row--JRiVW";
export var doctorName = "gen4Awards-module--doctor-name--2PrS2";
export var col3 = "gen4Awards-module--col-3--3A8Vu";
export var col9 = "gen4Awards-module--col-9--KNK5M";
export var awardsContainer = "gen4Awards-module--awards-container--3WN8O";