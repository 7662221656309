// extracted by mini-css-extract-plugin
export var section = "cta-module--section--2z9JM";
export var contentContainer = "cta-module--content-container--3-OPo";
export var gradient = "cta-module--gradient--UvOdK";
export var content = "cta-module--content--2pOtr";
export var text = "cta-module--text--zXY7w";
export var span = "cta-module--span--2Wzuo";
export var button = "cta-module--button--16Cjc";
export var line = "cta-module--line--17VlF";
export var label = "cta-module--label--1xTsz";
export var image = "cta-module--image--2iD8a";
export var footer = "cta-module--footer--1DBZA";
export var copyrightContainer = "cta-module--copyright-container--3pFBS";
export var copyright = "cta-module--copyright--2kszc";
export var branding = "cta-module--branding--HH0Be";
export var privacyPolicyContainer = "cta-module--privacy-policy-container--3sJ6d";
export var privacyPolicy = "cta-module--privacy-policy--5q3Ed";
export var socialLinks = "cta-module--social-links--m8tB2";
export var socialLink = "cta-module--social-link--2e8NU";
export var icon = "cta-module--icon--1asUM";
export var footer4 = "cta-module--footer4--31Pg8";