// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "links-module--background-cover-center--PNdkQ";
export var backgroundCoverTop = "links-module--background-cover-top--1_qFJ";
export var backgroundContainTop = "links-module--background-contain-top--3naJA";
export var backgroundContainCenter = "links-module--background-contain-center--4HQDB";
export var backgroundContainLeft = "links-module--background-contain-left--Tgnr1";
export var section = "links-module--section--IizX1";
export var links = "links-module--links--2sNcg";
export var link = "links-module--link--3dk1K";
export var label = "links-module--label--1Tmvu";
export var active = "links-module--active--lmyvu";