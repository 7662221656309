// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "tabsWithRichText-module--background-cover-center--1Bjjh";
export var backgroundCoverTop = "tabsWithRichText-module--background-cover-top--3jV-9";
export var backgroundContainTop = "tabsWithRichText-module--background-contain-top--2isc2";
export var backgroundContainCenter = "tabsWithRichText-module--background-contain-center--H5YrJ";
export var backgroundContainLeft = "tabsWithRichText-module--background-contain-left--1ioQR";
export var section = "tabsWithRichText-module--section--wChGL";
export var headingContainer = "tabsWithRichText-module--heading-container--1YSPi";
export var heading = "tabsWithRichText-module--heading--1PAu6";
export var links = "tabsWithRichText-module--links--bcXir";
export var programLink = "tabsWithRichText-module--program-link--sRzmT";
export var iconLabel = "tabsWithRichText-module--icon-label--1PUBG";
export var active = "tabsWithRichText-module--active--3eR8W";
export var program = "tabsWithRichText-module--program--VzuYs";
export var programLabel = "tabsWithRichText-module--program-label--28HEy";
export var fadeTop = "tabsWithRichText-module--fade-top--1hHHt";
export var fadeBottom = "tabsWithRichText-module--fade-bottom--3AHmU";
export var textContainer = "tabsWithRichText-module--text-container--3jczn";
export var paragraph = "tabsWithRichText-module--paragraph--uLD5O";
export var image = "tabsWithRichText-module--image--1nyI5";
export var arrowContainer = "tabsWithRichText-module--arrow-container--3WY_E";
export var arrow = "tabsWithRichText-module--arrow--vZCHt";