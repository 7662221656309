import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'
import { Parallax } from 'react-scroll-parallax'

import * as styles from './tabs.module.scss'

const Tabs = ({ component: { id, image, tabGroups } }) => {
  const [active, setActive] = useState(0)

  return (
    <section id={id} className={styles.section}>
      <Fade left cascade>
        <div className={styles.links}>
          {tabGroups.map((tab, i) => {
            const handleChangeActive = () => {
              setActive(i)
            }
            return (
              <button key={i} className={styles.programLink + `${active === i ? ` ${styles.active}` : ''}`} onClick={handleChangeActive}>
                <p className={styles.iconLabel}>{tab.label}</p>
              </button>
            )
          })}
        </div>
      </Fade>

      <Fade right delay={500}>
        <div className={styles.image + ' background-cover-center'} style={{ backgroundImage: `url(${image})` }} />
      </Fade>
      <Fade left delay={1000}>
        <div className={styles.arrowContainer}>
          <Parallax x={[-100, 100]}>
            <div className={styles.arrow + ' background-contain-center'} />
          </Parallax>
        </div>
      </Fade>
      {tabGroups && tabGroups[active] &&
        <div className={styles.program}>
          <Fade down>
            <div className={styles.fadeTop} />
            <div className={styles.fadeBottom} />
            <div className={styles.textContainer}>
              {tabGroups[active].content.split('\n').map((paragraph, i) => (
                <p key={i} className={styles.paragraph}>{paragraph}</p>
              ))}
            </div>
          </Fade>
        </div>}
    </section>
  )
}

export default Tabs

export const query = graphql`
  fragment Tabs on PagesJson {
    components {
      image
      tabGroups {
        label
        content
      }
    }
  }
`
