import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import { Page } from '../../../../../utilities'

import * as styles from './links.module.scss'

const Links = ({ openForm, menuOpen, scrolled, handleToggleMenu }) => {
  const [links, setLinks] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (menuOpen) {
      setCount(links.length)
    } else {
      setCount(0)
    }
  }, [menuOpen])

  const { allSettingsJson } = useStaticQuery(graphql`{
    allSettingsJson(filter: {fields: {slug: {eq: "/settings/settings/"}}}) {
      nodes {
        header {
          links {
            icon
            label
            page
            type
            url
          }
        }
      }
    }
  }`)

  useEffect(() => {
    setLinks(allSettingsJson.nodes[0].header.links)
  }, [allSettingsJson])

  return (
    <section className={styles.section + `${scrolled ? ` ${styles.scrolled}` : ''}` + `${menuOpen ? ` ${styles.active}` : ''}`} onClick={handleToggleMenu}>
      <ul className={styles.links}>
        {links.slice(0, count).map((link, i) => link.type === 'Page'
          ? (
            <Fade up delay={i * 100}>
              <Page className={styles.link} activeClassName={styles.active} key={i} page={link.page}>
                {link.icon && <div className={styles.icon} style={{ backgroundImage: `url(${link.icon})` }} />}
                <p className={styles.label}>{link.label}</p>
              </Page>
            </Fade>
          )
          : link.type === 'URL'
            ? (
              <a className={styles.link} key={i} href={link.url} target='_blank' rel='noopener noreferrer'>
                {link.icon && <div className={styles.icon} style={{ backgroundImage: `url(${link.icon})` }} />}
                <p className={styles.label}>{link.label}</p></a>
            )
            : (
              <div className={styles.link} onClick={openForm} key={i}>
                {link.icon && <div className={styles.icon} style={{ backgroundImage: `url(${link.icon})` }} />}
                <p className={styles.label}>{link.label}</p>
              </div>
            ))}
      </ul>
    </section>
  )
}

export default Links
