import React, { useState } from "react";
import { graphql } from "gatsby";
// import './gen4Agenda.module.scss';
// import parse from "html-react-parser";
// import { Fade } from "react-reveal";
// import { Parallax } from "react-scroll-parallax";
// import './gen4agenda.scss'
// import * as styles from "./gen4agenda.module.scss";

const Gen4Agenda = ({ component: { id, items, label } }) => {
  const [activeItem, setActiveItem] = useState<number>(1);

  return (
    <section className="gen4-schedule-container" id="gen4-agenda">
      <div className="gen4-schedule-buttons">
        <span>Select Event Schedule:</span>
        <p
          id="gen4-schedule-1"
          className={activeItem === 1 && "selected"}
          onClick={() => setActiveItem(1)}
        >
          October 6
        </p>
        <p
          id="gen4-schedule-2"
          className={activeItem === 2 && "selected"}
          onClick={() => setActiveItem(2)}
        >
          October 7
        </p>
        <p
          id="gen4-schedule-3"
          className={activeItem === 3 && "selected"}
          onClick={() => setActiveItem(3)}
        >
          October 8
        </p>
        <p
          id="gen4-schedule-4"
          className={activeItem === 4 && "selected"}
          onClick={() => setActiveItem(4)}
        >
          October 9
        </p>
      </div>

      <p className="gen4-schedule-description">
        Each day of the 4Front symposium will feature Main Stage events that all
        attendees should be present for and 4 smaller breakout sessions
        (highlighted in yellow) that attendees can choose to attend as they see
        fit.
      </p>

      {activeItem === 1 && (
        <div className="gen4-schedule" id="gen4-schedule-2">
          <div className="gen4-schedule-head">
            <span className="gen4-col-time">Time</span>
            <span className="gen4-col-event">Events</span>
          </div>

          <div className="gen4-schedule-body">
            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">4:00 pm</div>
              <div className="gen4-cell-event">
                {/* <span className="gen4-event-location">Lobby:</span> */}
                <p className="gen4-event-title">Hotel Check-In</p>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">3:00 - 7:00 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">Gold Room & Patio:</span>
                <p className="gen4-event-title">
                  4Front Registration & Headshots
                </p>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">7:00 - 9:00 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">Paradise Garden:</span>
                <p className="gen4-event-title">Opening Reception</p>
              </div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">Valley Room:</span>
                <p className="gen4-event-title">Headshots</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end of 1st table */}

      {activeItem === 2 && (
        <div className="gen4-schedule" id="gen4-schedule-1">
          <div className="gen4-schedule-head">
            <span className="gen4-col-time">Time</span>
            <span className="gen4-col-event">Events</span>
          </div>

          <div className="gen4-schedule-body">
            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">7:00 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Foyer:</span>
                <p className="gen4-event-title">4Front Registration</p>
              </div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Patio:</span>
                <p className="gen4-event-title">Breakfast</p>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">8:30 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Welcome</p>
                <span className="gen4-event-speaker">
                  Bill Walker & Bryce Block VP of M&A
                </span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">8:40 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">The Gen4 Model</p>
                <span className="gen4-event-speaker">Lamonte Jensen, CEO</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">9:00 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Individual Growth Engine</p>
                <span className="gen4-event-speaker">
                  Dr Mitch Ellingson, CCO
                </span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">9:20 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Envista Intro</p>
                <span className="gen4-event-speaker">Envista</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">9:30 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">
                  Gen4 Virtual Orthodontics Program
                </p>
                <span className="gen4-event-speaker">Dr. Tom Chamberlain</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">9:50 am</div>
              <div
                className="gen4-cell-event"
                style={{ backgroundColor: "#ffd36a" }}
              >
                {/* <span className="gen4-event-location">McArthur Ballroom:</span> */}
                <p
                  className="gen4-event-title"
                  style={{ backgroundColor: "#ffd87b" }}
                >
                  Break
                </p>
                {/* <span className="gen4-event-speaker">Straumann</span> */}
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">10:05 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Straumann Intro</p>
                <span className="gen4-event-speaker">Straumann</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">10:15 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">
                  Making It Easy for Patients to Say “YES!
                </p>
                <span className="gen4-event-speaker">Dr. Paul Homoly</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">11:20 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Leap Foundation</p>
                <span className="gen4-event-speaker">Dr. Bill Dorfman</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">11:30 am</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Patio:</span>
                <p className="gen4-event-title">Lunch</p>
              </div>
            </div>

            <div className="gen4-schedule-row highlight">
              <div className="gen4-cell-time">12:30 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 2:</span>
                <p className="gen4-event-title">Referral Tech (Perio)</p>
                <span className="gen4-event-speaker">Dr. Jason Stoner</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 3:</span>
                <p className="gen4-event-title">
                  Hybrid dentures, a digital workflow from start to finish
                </p>
                <span className="gen4-event-speaker">
                  Dr. Bryan Shanahan, Dr. Jack Marrano, Conrad Rensburg
                </span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 6:</span>
                <p className="gen4-event-title">
                  Gen4 Virtual Orthodontics Program
                </p>
                <span className="gen4-event-speaker">Dr. Tom Chamberlain</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 7:</span>
                <p className="gen4-event-title">
                  CANCELED - <s>Culture (Current vs. Aspirational, What is Culture, Creating
                    and Maintaining)</s>
                </p>
                <span className="gen4-event-speaker">People Ops Team</span>
              </div>
            </div>

            <div className="gen4-schedule-row highlight">
              <div className="gen4-cell-time">1:20 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 2:</span>
                <p className="gen4-event-title">Referral Tech (Perio)</p>
                <span className="gen4-event-speaker">Dr. Jason Stoner</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 3:</span>
                <p className="gen4-event-title">
                  Hybrid dentures, a digital workflow from start to finish
                </p>
                <span className="gen4-event-speaker">
                  Dr. Bryan Shanahan, Dr. Jack Marrano, Conrad Rensburg
                </span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 6:</span>
                <p className="gen4-event-title">
                  Neodent (Straumann Group) Workflow and Hands On
                </p>
                <span className="gen4-event-speaker">Straumann</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 7:</span>
                <p className="gen4-event-title">
                  Nuts and Bolts of Pediatric Dentistry
                </p>
                <span className="gen4-event-speaker">Dr. Jenny Keen</span>
              </div>
            </div>

            <div className="gen4-schedule-row highlight">
              <div className="gen4-cell-time">2:10 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 2:</span>
                <p className="gen4-event-title">
                  Mentor Training - Mentor Doctors
                </p>
                <span className="gen4-event-speaker">
                  Dr. Mitch Ellingson, CCO
                </span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 3:</span>
                <p className="gen4-event-title">
                  Our business, Our Responsibility
                </p>
                <span className="gen4-event-speaker">Dr. Joseph Field</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 6:</span>
                <p className="gen4-event-title">
                  Gen4 Virtual Orthodontics Program
                </p>
                <span className="gen4-event-speaker">Dr. Tom Chamberlain</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 7:</span>
                <p className="gen4-event-title">
                  Nuts and Bolts of Pediatric Dentistry
                </p>
                <span className="gen4-event-speaker">Dr. Jenny Keen</span>
              </div>
            </div>

            <div className="gen4-schedule-row highlight">
              <div className="gen4-cell-time">3:00 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 2:</span>
                <p className="gen4-event-title">Referral Tech (Perio)</p>
                <span className="gen4-event-speaker">Dr. Jason Stoner</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 3:</span>
                <p className="gen4-event-title">
                  Hybrid dentures, a digital workflow from start to finish
                </p>
                <span className="gen4-event-speaker">
                  Dr. Bryan Shanahan, Dr. Jack Marrano, Conrad Rensburg
                </span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 6:</span>
                <p className="gen4-event-title">
                  Gen4 Virtual Orthodontics Program
                </p>
                <span className="gen4-event-speaker">Dr. Tom Chamberlain</span>
              </div>

              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur 7:</span>
                <p className="gen4-event-title">
                  Practices use analytics and patient-facing tech to increase
                  case acceptance and scheduling.
                </p>
                <span className="gen4-event-speaker">CareStack</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">4:00 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Office Vibe</p>
                <span className="gen4-event-speaker">
                  Spencer Pierce, VP of People & Culture
                </span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">4:20 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">
                  Increase Case Acceptance with Technology
                </p>
                <span className="gen4-event-speaker">Dr. Brian Harris</span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">4:50 pm</div>
              <div className="gen4-cell-event">
                <span className="gen4-event-location">McArthur Ballroom:</span>
                <p className="gen4-event-title">Day #1 Wrap-Up</p>
                <span className="gen4-event-speaker">
                  Amy McNeill, Director of Marketing
                </span>
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">5:00 pm - 6:30pm</div>
              <div className="gen4-cell-event">
                {/* <span className="gen4-event-location">McArthur Ballroom:</span> */}
                <p className="gen4-event-title">Sponsor Booths</p>
                {/* <span className="gen4-event-speaker">
                  Amy McNeill, Director of Marketing
                </span> */}
              </div>
            </div>

            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">7:00 - 9:00pm</div>
              <div className="gen4-cell-event">
                {/* <span className="gen4-event-location">McArthur Ballroom:</span> */}
                <p className="gen4-event-title">Top Golf (Includes Dinner)</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end of 2nd table */}

      {activeItem === 3 && (
        <div className="gen4-schedule" id="gen4-schedule-1">
          <div className="gen4-schedule-head">
            <span className="gen4-col-time">Time</span>
            <span className="gen4-col-event">Events</span>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">7:00 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">Gold Room & Patio:</span>
              <p className="gen4-event-title">Breakfast</p>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">8:30 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Welcome</p>
              <span className="gen4-event-speaker">
                Bill Walker & Bryce Block VP of M&A
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">8:40 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Doctor Led Model</p>
              <span className="gen4-event-speaker">
                Dr Mitch Ellingson, CCO
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">9:00 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Intro to Gen4um</p>
              <span className="gen4-event-speaker">Dr. Packer</span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">9:10 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Envista Intro</p>
              <span className="gen4-event-speaker">Envista</span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">9:20 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">
                Panel: Different Generations of Dentistry
              </p>
              <span className="gen4-event-speaker">
                Dr Mitch Ellingson, CCO
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">9:50 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Esthetic Professionals Intro</p>
              <span className="gen4-event-speaker">Esthetic Professionals</span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">9:55 am</div>
            <div
              className="gen4-cell-event"
              style={{ backgroundColor: "#ffd87b" }}
            >
              {/* <span className="gen4-event-location">McArthur Ballroom:</span> */}
              <p className="gen4-event-title">Break</p>
              {/* <span className="gen4-event-speaker">Esthetic Professionals</span> */}
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">10:10 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">M&A Message</p>
              <span className="gen4-event-speaker">
                Bill Walker & Bryce Block VP of M&A
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">10:30 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">ADA Intro</p>
              <span className="gen4-event-speaker">ADA Video</span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">10:40 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Clinical Excellence</p>
              <span className="gen4-event-speaker">Lamonte Jensen , CEO</span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">11:00 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">
                Panel: Women at the 4Front of the Dental Industry
              </p>
              <span className="gen4-event-speaker">
                Spencer Pierce, VP of People & Culture
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">11:30 am</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">Gold Room & Patio:</span>
              <p className="gen4-event-title">Lunch</p>
            </div>
          </div>

          <div className="gen4-schedule-row highlight">
            {/* continue */}
            <div className="gen4-cell-time">12:30 pm</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 2:</span>
              <p className="gen4-event-title">My Implant Journey</p>
              <span className="gen4-event-speaker">Dr. Kim Cassity</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 3:</span>
              <p className="gen4-event-title">The 4Front of Gen4 Marketing</p>
              <span className="gen4-event-speaker">
                Amy McNeill, Director of Marketing & Marketing Team
              </span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 6:</span>
              <p className="gen4-event-title">ConfiDENTAL</p>
              <span className="gen4-event-speaker">
                Dr. Guy Gross, Neil Ward
              </span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 7:</span>
              <p className="gen4-event-title">
                Culture (Current vs. Aspirational, What is Culture, Creating and
                Maintaining)
              </p>
              <span className="gen4-event-speaker">People Ops Team</span>
            </div>
          </div>

          <div className="gen4-schedule-row highlight">
            <div className="gen4-cell-time">1:20 pm</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 2:</span>
              <p className="gen4-event-title">My Implant Journey</p>
              <span className="gen4-event-speaker">Dr. Kim Cassity</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 3:</span>
              <p className="gen4-event-title">Hands on with Implants</p>
              <span className="gen4-event-speaker">Envista, Nobel Biocare</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 6:</span>
              <p className="gen4-event-title">Social Media Marketing</p>
              <span className="gen4-event-speaker">Dr. Bill Dorfman</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 7:</span>
              <p className="gen4-event-title">
                OPERATIONS TEAM ONLY - Leadership Development
              </p>
              <span className="gen4-event-speaker">
                Spencer Pierce, VP of People & Culture
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row highlight">
            <div className="gen4-cell-time">2:10 pm</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 2:</span>
              <p className="gen4-event-title">My Implant Journey</p>
              <span className="gen4-event-speaker">Dr. Kim Cassity</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 3:</span>
              <p className="gen4-event-title">Hands on with Implants</p>
              <span className="gen4-event-speaker">Envista, Nobel Biocare</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 6:</span>
              <p className="gen4-event-title">ConfiDENTAL</p>
              <span className="gen4-event-speaker">
                Dr. Guy Gross, Neil Ward
              </span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 7:</span>
              <p className="gen4-event-title">
                OPERATIONS TEAM ONLY - Leadership Development
              </p>
              <span className="gen4-event-speaker">
                Spencer Pierce, VP of People & Culture
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row highlight">
            <div className="gen4-cell-time">3:00 pm</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 2:</span>
              <p className="gen4-event-title">
                Mentor Training - Mentor Doctors Only
              </p>
              <span className="gen4-event-speaker">
                Dr. Mitch Ellingson, CCO
              </span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 3:</span>
              <p className="gen4-event-title">Hands on with Implants</p>
              <span className="gen4-event-speaker">Envista, Nobel Biocare</span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 6:</span>
              <p className="gen4-event-title">ConfiDENTAL</p>
              <span className="gen4-event-speaker">
                Dr. Guy Gross, Neil Ward
              </span>
            </div>

            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur 7:</span>
              <p className="gen4-event-title">
                OPERATIONS TEAM ONLY: Culture (Current vs. Aspirational, What is Culture,
                Creating and Maintaining)
              </p>
              <span className="gen4-event-speaker">
                Spencer Pierce, VP of People & Culture
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">4:00 pm</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Awards</p>
              <span className="gen4-event-speaker">
                Lamonte Jensen, CEO, Dr. Mitch Ellingson, CCO
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">4:30 pm</div>
            <div className="gen4-cell-event">
              <span className="gen4-event-location">McArthur Ballroom:</span>
              <p className="gen4-event-title">Conference Wrap-Up</p>
              <span className="gen4-event-speaker">
                Amy McNeill, Director of Marketing
              </span>
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">4:35 pm - 6:30 pm</div>
            <div className="gen4-cell-event">
              {/* <span className="gen4-event-location">McArthur Ballroom:</span> */}
              <p className="gen4-event-title">Sponsor Booths</p>
              {/* <span className="gen4-event-speaker">
                Amy McNeill, Director of Marketing
              </span> */}
            </div>
          </div>

          <div className="gen4-schedule-row">
            <div className="gen4-cell-time">7:00 pm - 9:00 pm</div>
            <div className="gen4-cell-event">
              {/* <span className="gen4-event-location">McArthur Ballroom:</span> */}
              <p className="gen4-event-title">Group Dinners</p>
              {/* <span className="gen4-event-speaker">
                Amy McNeill, Director of Marketing
              </span> */}
            </div>
          </div>
        </div>
      )}
      {/* end of 3rd table */}

      {activeItem === 4 && (
        <div className="gen4-schedule" id="gen4-schedule-2">
          <div className="gen4-schedule-head">
            <span className="gen4-col-time">Time</span>
            <span className="gen4-col-event">Events</span>
          </div>

          <div className="gen4-schedule-body">
            <div className="gen4-schedule-row">
              <div className="gen4-cell-time">11:00 am</div>
              <div className="gen4-cell-event">
                {/* <span className="gen4-event-location">Lobby:</span> */}
                <p className="gen4-event-title">Hotel Check-Out</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end of 4th table */}

      <a className="gen4-agenda-scroll-to-top" href="#gen4-agenda">
        Back To Top
      </a>
    </section>
  );
};

export default Gen4Agenda;

export const query = graphql`
  fragment Gen4Agenda on PagesJson {
    components {
      label
    }
  }
`;
