// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "alternatingList-module--background-cover-center--8vXHP";
export var backgroundCoverTop = "alternatingList-module--background-cover-top--17Yvx";
export var backgroundContainTop = "alternatingList-module--background-contain-top--2IMuO";
export var backgroundContainCenter = "alternatingList-module--background-contain-center--1OjEb";
export var backgroundContainLeft = "alternatingList-module--background-contain-left--18LbX";
export var section = "alternatingList-module--section--3pGz9";
export var listItem = "alternatingList-module--list-item--3c3v7";
export var media = "alternatingList-module--media--uve4f";
export var videoContainer = "alternatingList-module--video-container--2Hir8";
export var reactPlayer = "alternatingList-module--react-player--2vdS-";
export var image = "alternatingList-module--image--k5rIA";
export var content = "alternatingList-module--content--2jY2e";
export var heading = "alternatingList-module--heading--1xR7q";
export var text = "alternatingList-module--text--2H_Q5";
export var buttons = "alternatingList-module--buttons--2vEyo";
export var button = "alternatingList-module--button--11yF7";
export var line = "alternatingList-module--line--3E-Td";
export var label = "alternatingList-module--label--3tz3T";