import React from 'react'
import { graphql, Link } from 'gatsby'
import { Fade } from 'react-reveal'
import ReactPlayer from 'react-player'

import * as styles from './videoHero.module.scss'

const VideoHero = ({ component: { image, content, id, heading = '', button } }) => {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.container}>
        <ReactPlayer className={styles.video} controls={false} width='100%' height='100%' url={image} playing muted loop volume={0} config={{ youtube: { playerVars: { controls: 0, disablekb: 1, modestbranding: 1, showinfo: 0 } }, vimeo: { playerOptions: { byline: false, controls: 0, dnt: true, keyboard: false, title: false } } }} />
      </div>
      <div className={styles.mobile}>
        <video className={styles.video} src={image} autoPlay muted loop />
      </div>
      <div className={styles.content}>
        <div className={styles.textContainer + `${!button || !button.display ? ` ${styles.noButton}` : ''}`}>
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          <h2 className={styles.bannerText}>
            {content && content.split('\n').map((line, i) => <Fade key={i} left cascade delay={500 * i}>
              <span className={styles.line}>{line}</span>
            </Fade>)}
          </h2>
          {button && button.display
            ? (
              <Link className={styles.button} to={button.link}>
                <div className={styles.line} />
                <Fade right cascade delay={1000}>
                  <p className={styles.label}>{button.label}</p>
                </Fade>
              </Link>
            )
            : null}
        </div>
      </div>
    </section>
  )
}

export default VideoHero

export const query = graphql`
  fragment VideoHero on PagesJson {
    components {
      image
      content
      button {
        display
        label
        link
      }
    }
  }
`
