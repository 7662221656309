// extracted by mini-css-extract-plugin
export var section = "people-module--section--1dRDN";
export var thumbnailsContainer = "people-module--thumbnails-container--2p_nz";
export var label = "people-module--label--16okh";
export var thumbnails = "people-module--thumbnails--kZS8X";
export var member = "people-module--member--3zBRS";
export var profile = "people-module--profile--1FuU7";
export var images = "people-module--images--OM_8s";
export var candid = "people-module--candid--3Pq6P";
export var image = "people-module--image--2vxXJ";
export var name = "people-module--name--2xons";
export var title = "people-module--title--3T5CV";
export var bio = "people-module--bio--1pH-2";
export var paragraph = "people-module--paragraph--1MQv2";
export var arrows = "people-module--arrows--382RY";
export var left = "people-module--left--_aJjx";
export var right = "people-module--right--lnX3M";