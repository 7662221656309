// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "pediatric-module--background-cover-center--3xm5K";
export var backgroundCoverTop = "pediatric-module--background-cover-top--2qDL6";
export var backgroundContainTop = "pediatric-module--background-contain-top--3gvXK";
export var backgroundContainCenter = "pediatric-module--background-contain-center--30o67";
export var backgroundContainLeft = "pediatric-module--background-contain-left--3WGtm";
export var colContainer = "pediatric-module--colContainer--_2WZY";
export var col = "pediatric-module--col--37JaC";
export var headlineBox = "pediatric-module--headlineBox--1Dc1y";
export var mb5vw = "pediatric-module--mb5vw--Q0AsD";
export var yellowBox = "pediatric-module--yellowBox--vwT1y";
export var squareBtn = "pediatric-module--squareBtn--wkvdW";
export var pediatricContainer = "pediatric-module--pediatric-container--3Hv_H";
export var gen4AgendaScrollToTop = "pediatric-module--gen4-agenda-scroll-to-top--92i3r";
export var button = "pediatric-module--button--kb2JA";
export var line = "pediatric-module--line--1Vi57";
export var label = "pediatric-module--label--3mY1u";
export var media = "pediatric-module--media--1nNnb";
export var videoContainer = "pediatric-module--video-container--2LLns";
export var reactPlayer = "pediatric-module--react-player--3nSiW";
export var image = "pediatric-module--image--13wzD";