// extracted by mini-css-extract-plugin
export var section = "contactdrreferrals-module--section--3Njvg";
export var image = "contactdrreferrals-module--image--izr_J";
export var contactInfo = "contactdrreferrals-module--contact-info--LNTxn";
export var heading = "contactdrreferrals-module--heading--3NcG-";
export var phoneNumber = "contactdrreferrals-module--phone-number--MzUdR";
export var label = "contactdrreferrals-module--label--13Zre";
export var address = "contactdrreferrals-module--address--JTwYu";
export var line = "contactdrreferrals-module--line--2Almz";
export var formContainer = "contactdrreferrals-module--form-container--3o4LR";
export var form = "contactdrreferrals-module--form--1h8qk";
export var field = "contactdrreferrals-module--field--1vNub";
export var fieldset = "contactdrreferrals-module--fieldset--3tJu5";
export var textarea = "contactdrreferrals-module--textarea--1O5-U";
export var number = "contactdrreferrals-module--number--1rY2j";
export var formHeading = "contactdrreferrals-module--form-heading--2iUev";
export var fieldsetNumber = "contactdrreferrals-module--fieldsetNumber--2VGWP";
export var addReferralBtn = "contactdrreferrals-module--addReferralBtn--1uIat";
export var button = "contactdrreferrals-module--button--W7ZTE";
export var submitted = "contactdrreferrals-module--submitted--3Et8u";
export var message = "contactdrreferrals-module--message--1Y1nj";
export var footerContainer = "contactdrreferrals-module--footer-container--2HmOv";
export var footer = "contactdrreferrals-module--footer--B6Zww";
export var copyrightContainer = "contactdrreferrals-module--copyright-container--3ap9z";
export var copyright = "contactdrreferrals-module--copyright--1YYgq";
export var branding = "contactdrreferrals-module--branding--2tgWL";
export var span = "contactdrreferrals-module--span--wB17M";
export var privacyPolicyContainer = "contactdrreferrals-module--privacy-policy-container--Ms5wQ";
export var privacyPolicy = "contactdrreferrals-module--privacy-policy--29-9v";
export var socialLinks = "contactdrreferrals-module--social-links--3XbIv";
export var socialLink = "contactdrreferrals-module--social-link--1VfVK";
export var icon = "contactdrreferrals-module--icon--3YTSq";
export var footer4 = "contactdrreferrals-module--footer4--26YCw";