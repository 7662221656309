// extracted by mini-css-extract-plugin
export var section = "accordion-module--section--3NP4a";
export var arrowContainer = "accordion-module--arrow-container--3j7oq";
export var arrow = "accordion-module--arrow--3kl5v";
export var label = "accordion-module--label--3ZhdB";
export var items = "accordion-module--items--198jY";
export var item = "accordion-module--item--3E6oa";
export var header = "accordion-module--header--2YuLw";
export var title = "accordion-module--title--3yDlZ";
export var icon = "accordion-module--icon--3w5Qu";
export var text = "accordion-module--text--E3pDN";
export var active = "accordion-module--active--WNexo";