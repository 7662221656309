import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './staffCarousel.module.scss'

const StaffCarousel = ({ component: { id, label } }) => {
  const { staffJson: { staff } } = useStaticQuery(graphql`{
    staffJson(fields: {slug: {eq: "/staff/staff/"}}) {
      staff {
        name
        title
        bio
        image
        candid
      }
    }
  }`)

  const [active, setActive] = useState(0)
  const [candid, setCandid] = useState(false)

  // const handleNext = () => {
  //   setActive(active === staff.length - 1 ? 0 : active + 1)
  //   setCandid(false)
  // }

  // const handlePrevious = () => {
  //   setActive(active === 0 ? staff.length - 1 : active - 1)
  //   setCandid(false)
  // }

  const handleToggleCandid = () => {
    setCandid(!candid)
  }

  return (
    <section id={id} className={styles.section}>
      <Fade left>
        <div className={styles.thumbnailsContainer}>
          <h2 className={styles.label}>{label}</h2>
        <div className={styles.thumbnails}>
          {staff.map((member, i) => {
            const handleClick = () => {
              setActive(i)
            }
            return (
              <div onClick={handleClick} key={i} className={styles.member + ' background-cover-center'} style={{ backgroundImage: `url(${member.image})`}} />
            )
          })}
        </div></div>
      </Fade>
      {/* <Fade down delay={500}>
        <div className={styles.arrows}>
          <div className={styles.left} onClick={handlePrevious} />
          <div className={styles.right} onClick={handleNext} />
        </div>
      </Fade> */}
      <Fade right delay={250}>
        <div className={styles.profile}>
          <Fade right delay={500}>
            <div className={styles.images}>
            {staff[active].candid && staff[active].candid !== staff[active].image ? <div className={styles.candid + ' background-cover-center'} style={{ backgroundImage: `url(${staff[active].candid})` }} /> : null}
            <div className={styles.image + ' background-cover-top'} style={{ backgroundImage: `url(${candid && staff[active].candid ? staff[active].candid : staff[active].image})` }} onClick={handleToggleCandid} />
            </div>
          </Fade>
          <Fade right delay={750}>
            <h2 className={styles.name}>{staff[active].name}</h2>
          </Fade>
          <Fade right delay={1000}>
            <h3 className={styles.title}>{staff[active].title}</h3>
          </Fade>
          <Fade right>
          <div className={styles.bio}>
            {staff[active].bio.split('\n').map((paragraph, i) => (
                <p key={i} className={styles.paragraph}>{paragraph}</p>
            ))}
          </div>
          </Fade>
        </div>
      </Fade>
    </section>
  )
}

export default StaffCarousel
