// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "heroWithCountdown-module--background-cover-center--6QHJz";
export var backgroundCoverTop = "heroWithCountdown-module--background-cover-top--nz0u4";
export var backgroundContainTop = "heroWithCountdown-module--background-contain-top--1fIvU";
export var backgroundContainCenter = "heroWithCountdown-module--background-contain-center--2u0LM";
export var backgroundContainLeft = "heroWithCountdown-module--background-contain-left--IKHts";
export var section = "heroWithCountdown-module--section--2S0T8";
export var background = "heroWithCountdown-module--background--ycd4k";
export var content = "heroWithCountdown-module--content--2IJUO";
export var fill = "heroWithCountdown-module--fill--Pm4-T";
export var logo = "heroWithCountdown-module--logo--2xJNx";
export var textContainer = "heroWithCountdown-module--text-container--3AreT";
export var textDiv = "heroWithCountdown-module--text-div--27oxy";
export var bannerText = "heroWithCountdown-module--banner-text--2rTW4";
export var line = "heroWithCountdown-module--line--2YIV5";
export var countdownContainer = "heroWithCountdown-module--countdown-container--3tk8E";
export var countdownLabel = "heroWithCountdown-module--countdown-label--29wNy";
export var countdownTo = "heroWithCountdown-module--countdown-to---TGZn";