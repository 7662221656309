import React from "react";
import { graphql, Link } from "gatsby";
import { Fade } from "react-reveal";
import parse from "html-react-parser";
import Countdown from "react-countdown";

import * as styles from "./heroWithLogoAwardsArchive.module.scss";

const heroWithLogoAwardsArchive = ({
  component: { image, content, id, logo, countdown_label, countdown_to },
}) => {
  return (
    <section id={id} className={styles.section}>
      <Fade left delay={500}>
        <div
          className={styles.background + " background-cover-center"}
          style={{ backgroundImage: `url(${image})` }}
        />
      </Fade>
      <Fade left delay={600}>
        <div className={styles.content}>
          <div className={styles.fill} />
          <div
            className={styles.logo}
            style={{ backgroundImage: `url(${logo})` }}
          />
          <p className={styles.yearText}>Previous Years</p>
          <div className={styles.textContainer}>
            <div className={styles.textDiv}>
              {content ? parse(content) : null}
              <a
                className={styles.button}
                href="https://gen4dental.com/leaders-2023"
                rel="noopener noreferrer"
              >
                View 2023
              </a>
              <br></br>
              <a
                className={styles.button}
                href="https://gen4dental.com/leaders-2024"
                rel="noopener noreferrer"
              >
                View 2024
              </a>
            </div>
          </div>
          <div className={styles.countdownContainer}>
            <p className={styles.countdownLabel}>{countdown_label}</p>
            <Countdown className={styles.countdownTo} date={countdown_to} />
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default heroWithLogoAwardsArchive;

export const query = graphql`
  fragment HeroWithLogoAwardsArchive on PagesJson {
    components {
      image
      logo
      content
      countdown_label
      countdown_to
    }
  }
`;
