import React from 'react'
import { graphql, Link } from 'gatsby'
import { Fade } from 'react-reveal'
import parse from 'html-react-parser'
import Countdown from 'react-countdown'

import * as styles from './heroWithCountdown.module.scss'

const HeroWithCountdown = ({ component: { image, content, id, logo, countdown_label, countdown_to } }) => {
  return (
    <section id={id} className={styles.section}>
      <Fade left delay={500}>
        <div className={styles.background + ' background-cover-center'} style={{ backgroundImage: `url(${image})` }} />
      </Fade>
      <div className={styles.content}>
        <div className={styles.fill} />
          <div className={styles.logo} style={{ backgroundImage: `url(${logo})`}} />
        <div className={styles.textContainer}>
          <div className={styles.textDiv}>
          {content ? parse(content) : null}
          </div>
        </div>
        <div className={styles.countdownContainer}>
          <p className={styles.countdownLabel}>{countdown_label}</p>
          <Countdown className={styles.countdownTo} date={countdown_to} />
        </div>
      </div>
    </section>
  )
}

export default HeroWithCountdown

export const query = graphql`
  fragment HeroWithCountdown on PagesJson {
    components {
      image
      logo
      content
      countdown_label
      countdown_to
    }
  }
`
