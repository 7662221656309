import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fade } from "react-reveal";

import * as styles from "./awardsCarousel24.module.scss";

const AwardsCarousel24 = ({ component: { id, label } }) => {
  const {
    awardsJson: { awards },
  } = useStaticQuery(graphql`
    {
      awardsJson(fields: { slug: { eq: "/awards/awards24/" } }) {
        awards {
          name
          title
          job
          bio
          image
          candid
        }
      }
    }
  `);

  const [active, setActive] = useState(0);
  const [candid, setCandid] = useState(false);

  // const handleNext = () => {
  //   setActive(active === awards.length - 1 ? 0 : active + 1)
  //   setCandid(false)
  // }

  // const handlePrevious = () => {
  //   setActive(active === 0 ? awards.length - 1 : active - 1)
  //   setCandid(false)
  // }

  const handleToggleCandid = () => {
    setCandid(!candid);
  };

  return (
    <section id={id} className={styles.section}>
      <Fade left>
        <div className={styles.thumbnailsContainer}>
          <h2 className={styles.label}>{label}</h2>
          <div className={styles.thumbnails}>
            {awards.map((member, i) => {
              const handleClick = () => {
                setActive(i);
              };
              return (
                <div
                  onClick={handleClick}
                  key={i}
                  className={styles.member + " background-cover-center"}
                  style={{ backgroundImage: `url(${member.image})` }}
                />
              );
            })}
          </div>
        </div>
      </Fade>
      {/* <Fade down delay={500}>
        <div className={styles.arrows}>
          <div className={styles.left} onClick={handlePrevious} />
          <div className={styles.right} onClick={handleNext} />
        </div>
      </Fade> */}
      <Fade right delay={250}>
        <div className={styles.profile}>
          <Fade right delay={500}>
            <div className={styles.images}>
              {awards[active].candid &&
              awards[active].candid !== awards[active].image ? (
                <div
                  className={styles.candid + " background-cover-center"}
                  style={{ backgroundImage: `url(${awards[active].candid})` }}
                />
              ) : null}
              <div
                className={styles.image + " background-cover-top"}
                style={{
                  backgroundImage: `url(${
                    candid && awards[active].candid
                      ? awards[active].candid
                      : awards[active].image
                  })`,
                }}
                onClick={handleToggleCandid}
              />
            </div>
          </Fade>
          <Fade right delay={750}>
            <h2 className={styles.name}>{awards[active].name}</h2>
          </Fade>
          <Fade right delay={850}>
            <h3 className={styles.title}>{awards[active].title}</h3>
          </Fade>
          <Fade right>
            <div className={styles.bio}>
              <p className={styles.paragraph}>{awards[active].job}</p>
              {awards[active].bio.split("\n").map((paragraph, i) => (
                <p key={i} className={styles.paragraph}>
                  {paragraph}
                </p>
              ))}
            </div>
          </Fade>
        </div>
      </Fade>
    </section>
  );
};

export default AwardsCarousel24;
