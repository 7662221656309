import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'
import * as styles from './goals.module.scss'


const Goals = ({ component: { id, heading } }) => {
  const { goalsJson: { goals } } = useStaticQuery(graphql`{
    goalsJson(fields: {slug: {eq: "/goals/goals/"}}) {
      goals {
        icon
        heading
        copy
      }
    }
  }`)

  const [active, setActive] = useState(0)

  const handleNext = () => {
    setActive(active === goals.length - 1 ? 0 : active + 1)
  }

  const handlePrevious = () => {
    setActive(active === 0 ? goals.length - 1 : active - 1)
  }

  const getGoals = () => goals.slice(active).slice(0, 3).map(goal => ({ ...goal, id: Math.random() }))

  return (
    <section id={id} className={styles.section}>
      <div className={styles.header}>
        <h2 className={styles.heading}>{heading}</h2>
        {goals.length > 3 && (
          <div className={styles.arrows}>
            <div className={styles.left} onClick={handlePrevious} />
            <div className={styles.right} onClick={handleNext} />
          </div>
        )}
      </div>
      <div className={styles.goals}>
        {getGoals().map((goal, i) => (
          <Fade key={goal.id} down cascade delay={i * 250}>
            <div className={styles.goal}>
              <div className={styles.icon + ' background-contain-top'} style={{ backgroundImage: `url(${goal.icon})` }} />
              <div className={styles.slashContainer}>
                <div className={styles.slash} />
              </div>
              <h3 className={styles.heading}>{goal.heading}</h3>
              <p className={styles.copy}>{goal.copy}</p>
              {i < getGoals().length - 1 && <div className={styles.line} />}
            </div>
          </Fade>
        )
        )}
      </div>
    </section>
  )
}

export default Goals

export const query = graphql`
  fragment Goals on PagesJson {
    components {
      heading
    }
  }
`
