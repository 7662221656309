import React from 'react'
import { graphql, Link } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './hero.module.scss'

const Hero = ({ component: { image, content, id, heading = '', button } }) => {
  return (
    <section id={id} className={styles.section}>
      <Fade left delay={500}>
        <div className={styles.background + ' background-cover-center'} style={{ backgroundImage: `url(${image})` }} />
      </Fade>
      <div className={styles.content}>
        <div className={styles.textContainer + `${!button || !button.display ? ` ${styles.noButton}` : ''}`}>
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          <h2 className={styles.bannerText}>
            {content && content.split('\n').map((line, i) => <Fade key={i} left cascade delay={500 * i}>
              <span className={styles.line}>{line}</span>
            </Fade>)}
          </h2>
          {button && button.display
            ? (
              <Link className={styles.button} to={button.link}>
                <div className={styles.line} />
                <Fade right cascade delay={1000}>
                  <p className={styles.label}>{button.label}</p>
                </Fade>
              </Link>
            )
            : null}
        </div>
      </div>
    </section>
  )
}

export default Hero

export const query = graphql`
  fragment Hero on PagesJson {
    components {
      image
      content
      button {
        display
        label
        link
      }
    }
  }
`
