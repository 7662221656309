import React from 'react'
import { Fade } from 'react-reveal'
import { Parallax } from 'react-scroll-parallax'

import * as styles from './arrow.module.scss'

const Arrow = () => (
  <section className={styles.section}>
    <Fade down delay={1000}>
      <Parallax y={[-50, 50]}>
        <div className={styles.arrow + ` background-contain-center`} />
      </Parallax>
    </Fade>
  </section>
)

export default Arrow
