import React from 'react'

import Components from './components'

import * as styles from './main.module.scss'

const Main = ({ components, children, title }) => (
  <main className={styles.main}>
  {children}
    <div className={styles.components}>
      {components && components.length
        ? components.map((component, i) => {
          const index = Object.keys(Components).indexOf(component.template)
          const Component = index !== null && Object.values(Components)[index]
          return Component
            ? <Component key={i} component={component} title={title} />
            : <p key={i} className={styles.title}>The <strong>{component.template}</strong> component does not exist</p>
        })
        : null}
    </div>
  </main>
)

export default Main
