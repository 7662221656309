import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './news.module.scss'

const News = ({ component: { id } }) => {
  const { allMarkdownRemark: { nodes: news } } = useStaticQuery(graphql`{
    allMarkdownRemark(
      filter: {frontmatter: {publish: {eq: true}, index: {eq: true}}, fields: {type: {eq: "news"}}}
      sort: {fields: frontmatter___timestamp, order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          image
          description
          title
        }
      }
    }
  }`)

  return (
    <section id={id} className={styles.section}>
      <Fade top>
        <h2 className={styles.heading}>News</h2>
      </Fade>
      <div className={styles.news}>
        {news.map((article, i) => (
          <Fade key={i} left delay={i * 250}>
            <Link className={styles.article} to={article.fields.slug}>
              <div className={styles.image + ' background-cover-center'} style={{ backgroundImage: `url(${article.frontmatter.image})` }} />
              <p className={styles.title}>{article.frontmatter.title}</p>
              <p className={styles.description}>{article.frontmatter.description}</p>
            </Link>
          </Fade>
        ))}
      </div>
    </section>
  )
}

export default News
