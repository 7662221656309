// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "header-module--background-cover-center--OSCVj";
export var backgroundCoverTop = "header-module--background-cover-top--1Bxx9";
export var backgroundContainTop = "header-module--background-contain-top--3zy2N";
export var backgroundContainCenter = "header-module--background-contain-center--1hc6V";
export var backgroundContainLeft = "header-module--background-contain-left--23jI5";
export var header = "header-module--header--3n7pc";
export var scrolled = "header-module--scrolled--3p3JS";
export var menuButton = "header-module--menu-button--2VMXM";
export var labelContainer = "header-module--label-container--3zMiJ";
export var label = "header-module--label--1PUas";
export var buttonContainer = "header-module--button-container--1UF2D";
export var color = "header-module--color--QBLoa";
export var button = "header-module--button--2C2Wn";
export var open = "header-module--open--130b0";