// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "heroWithLogo-module--background-cover-center--2N812";
export var backgroundCoverTop = "heroWithLogo-module--background-cover-top--V-7cz";
export var backgroundContainTop = "heroWithLogo-module--background-contain-top--2WYs7";
export var backgroundContainCenter = "heroWithLogo-module--background-contain-center--2oMqs";
export var backgroundContainLeft = "heroWithLogo-module--background-contain-left--xtzmc";
export var section = "heroWithLogo-module--section--2FdRe";
export var background = "heroWithLogo-module--background--1Z0pC";
export var content = "heroWithLogo-module--content--2QbUH";
export var fill = "heroWithLogo-module--fill--2TgMT";
export var logo = "heroWithLogo-module--logo--2VHgm";
export var yearText = "heroWithLogo-module--year-text--3X-gu";
export var textContainer = "heroWithLogo-module--text-container--Dhc6C";
export var textDiv = "heroWithLogo-module--text-div--1gmZd";
export var bannerText = "heroWithLogo-module--banner-text--zNev2";
export var line = "heroWithLogo-module--line--2iu7Q";
export var countdownContainer = "heroWithLogo-module--countdown-container--106D0";
export var countdownLabel = "heroWithLogo-module--countdown-label--1X9w3";
export var countdownTo = "heroWithLogo-module--countdown-to--1DCjF";