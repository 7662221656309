// extracted by mini-css-extract-plugin
export var section = "goals-module--section--113OS";
export var header = "goals-module--header--1GCP7";
export var heading = "goals-module--heading--28ccb";
export var arrows = "goals-module--arrows--36Wr4";
export var left = "goals-module--left--3JHPh";
export var right = "goals-module--right--3arwk";
export var goals = "goals-module--goals--3b6qD";
export var goal = "goals-module--goal--261n0";
export var icon = "goals-module--icon--3FMGi";
export var slashContainer = "goals-module--slash-container--2hEqn";
export var slash = "goals-module--slash--3JJcM";
export var copy = "goals-module--copy--AHH2q";
export var line = "goals-module--line--3H7Hy";