// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "tabs-module--background-cover-center--1BvVW";
export var backgroundCoverTop = "tabs-module--background-cover-top--4Le2K";
export var backgroundContainTop = "tabs-module--background-contain-top--hPrkD";
export var backgroundContainCenter = "tabs-module--background-contain-center--HsgoU";
export var backgroundContainLeft = "tabs-module--background-contain-left--2scGD";
export var section = "tabs-module--section--2Zdwq";
export var links = "tabs-module--links--U-mNS";
export var programLink = "tabs-module--program-link--2lkxs";
export var iconLabel = "tabs-module--icon-label--2bNyn";
export var active = "tabs-module--active--18rGk";
export var program = "tabs-module--program--1-o47";
export var programLabel = "tabs-module--program-label--QKlqr";
export var fadeTop = "tabs-module--fade-top--LxnAK";
export var fadeBottom = "tabs-module--fade-bottom--1Z6mF";
export var textContainer = "tabs-module--text-container--3k6Jw";
export var paragraph = "tabs-module--paragraph--24ri6";
export var image = "tabs-module--image--1N36Y";
export var arrowContainer = "tabs-module--arrow-container--G8cFC";
export var arrow = "tabs-module--arrow--2GfeC";