import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import slugify from 'slugify'
import { Fade } from 'react-reveal'
import { Parallax } from 'react-scroll-parallax'

import * as styles from './partners.module.scss'

const Partners = ({ component: { heading, id } }) => {
  const [partners, setPartners] = useState([])

  const { allPartnersJson } = useStaticQuery(graphql`{
    allPartnersJson(filter: {fields: {slug: {eq: "/partners/partners/"}}}) {
      nodes {
        partners {
          name
          logo
        }
      }
    }
  }`)

  useEffect(() => {
    setPartners(allPartnersJson.nodes[0].partners)
  }, [allPartnersJson])

  return (
    <section id={id} className={styles.section}>
      <h2 className={styles.heading}>{heading}</h2>
      <div className={styles.partners}>
        {partners.map((partner, i) => (
          <div key={i} className={styles.partnerContainer}>
            <Link className={styles.partner + ' background-contain-center'} style={{ backgroundImage: `url(${partner.logo})` }} to={`/practices/${slugify(partner.name, { lower: true, strict: true })}`}>
            {!partner.logo ? <p className={styles.name}>{partner.name}</p> : null}
          </Link>
          </div>
        ))}
      </div>
      <div className={styles.arrowContainer}>
        <Fade down>
          <Parallax y={[-50, 50]}>
            <div className={styles.arrow + ` background-contain-center`} />
          </Parallax>
        </Fade>
      </div>
    </section>
  )
}

export default Partners
