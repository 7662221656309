import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import { Fade } from 'react-reveal'

import footer4 from './footer4.svg'
import tile from './tile.svg'

import * as styles from './cta.module.scss'

const Cta = ({ component: { text, image, button, id } }) => {
  const { settingsJson: { footerData } } = useStaticQuery(graphql`{
    settingsJson(fields: {slug: {eq: "/settings/settings/"}}) {
      footerData {
        copyright {
          link
          text
        }
        privacyPolicy {
          link
          text
        }
        social {
          icon
          link
        }
      }
    }
  }`)
  
  return (
    <section id={id} className={styles.section}>
      <div className={styles.contentContainer} style={{ backgroundImage: `url(${tile})` }}>
        <div className={styles.gradient} />
        <div className={styles.content}>
          {text && <h2 className={styles.text}>{text.split('\n').map((line, i) => (
            <Fade key={i} down cascade>
              <span className={styles.span}>{line}</span>
            </Fade>
          ))}</h2>}
          {button.display && button.link && button.label ? button.link.includes('mailto:') ? (
            <a className={styles.button} href={button.link}>
            <div className={styles.line} />
            <Fade right cascade delay={1000}>
              <p className={styles.label}>{button.label}</p>
            </Fade>
            </a>
          ) : (
            <Link className={styles.button} to={button.link}>
              <div className={styles.line} />
              <Fade right cascade delay={1000}>
                <p className={styles.label}>{button.label}</p>
              </Fade>
            </Link>
          ) : null}
        </div>
      </div>
      <Fade right delay={500}>
        <div className={styles.image + ` background-cover-center`} style={{ backgroundImage: `url(${image})` }} />
      </Fade>
      <footer className={styles.footer} style={{ backgroundImage: `url(${tile})` }}>
        <Fade up cascade>
          <div className={styles.copyrightContainer}>
            {footerData.copyright.link ? <a className={styles.copyright} href={footerData.copyright.link} target='_blank' rel='noopener noreferrer'>{footerData.copyright.text}</a> : <p className={styles.copyright}>{footerData.copyright.text}</p>}
            <span className={styles.span}> | </span>
            <a className={styles.branding} href='http://fireupyourbrand.com' target='_blank' rel='noopener noreferrer'> Website by Station8</a>
          </div>
        </Fade>
        <Fade up delay={500}>
          <div className={styles.privacyPolicyContainer}>
            <Link className={styles.privacyPolicy} to={footerData.privacyPolicy.link}>{footerData.privacyPolicy.text}</Link>
          </div>
        </Fade>
        {footerData.social && footerData.social.length
          ? <div className={styles.socialLinks}>
            {footerData.social.map((account, i) => (
              <Fade key={i} up delay={500 * i}>
                <a href={account.link} className={styles.socialLink} target='_blank' rel='noopener noreferrer'><div className={styles.icon + ` background-contain-center`} style={{ backgroundImage: `url(${account.icon})` }} /></a>
              </Fade>
            ))}
          </div>
          : null}
        <Fade right delay={1000}>
          <div className={styles.footer4} style={{ backgroundImage: `url(${footer4})` }} />
        </Fade>
      </footer>
    </section>
  )
}

export default Cta

export const query = graphql`
  fragment Cta on PagesJson {
    components {
      image
      text
      button {
        display
        label
        link
      }
    }
  }
`