import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import { Header, Main } from './components'

import * as styles from './layout.module.scss'

const Layout = ({ children = null, title, description, metaTags = [], author = '', location, image = '', slug, components }) => {
  const [formOpen, setFormOpen] = useState(false)
  const [settings, setSettings] = useState(undefined)

  const { allSettingsJson } = useStaticQuery(graphql`{
    allSettingsJson(filter: {fields: {slug: {eq: "/settings/settings/"}}}) {
      nodes {
        siteInfo {
          author
          description
          metaTags
          title
          titleDivider
        }
      }
    }
  }`)

  useEffect(() => {
    setSettings(allSettingsJson.nodes[0].siteInfo)
  }, [allSettingsJson])

  const openForm = () => {
    setFormOpen(true)
  }
  const handleCloseForm = () => {
    setFormOpen(false)
  }
  return settings
    ? (
      <ParallaxProvider>
        <div className={styles.layout + `${formOpen ? ` ${styles.disabled}` : ''}`}>
          <Helmet>
            <html lang='en' />
            <title>{slug === '/' ? settings.title : `${title || '404: Page Not Found'}` + settings.titleDivider + settings.title}</title>
            <link rel="stylesheet" href="https://s.pageclip.co/v1/pageclip.css" media="screen"></link>
            <script src="https://s.pageclip.co/v1/pageclip.js"></script>
            <meta name='description' content={description || settings.description} />
            <meta name='keywords' content={metaTags ? metaTags.join(' ') : settings.metaTags.join(' ')} />
            <meta name='author' content={author || settings.author} />
            <meta property='og:title' content={slug === '/' ? settings.title : `${title || '404: Page Not Found'}` + settings.titleDivider + settings.title} />
            <meta property='og:description' content={description || settings.description} />
            <meta property='og:image' itemProp='image' content={image} />
            <meta property='og:type' content='website' />
            <meta property='og:url' content={location.href} />
            <meta property='twitter:title' content={slug === '/' ? settings.title : `${title || '404: Page Not Found'}` + settings.titleDivider + settings.title} />
            <meta property='twitter:description' content={description || settings.description} />
            <meta name="google-site-verification" content="NKcgu1VfdAVQjBkoHAay9dtpDGSIlcS9t3l2Xn_l9h8" />
            <meta property='twitter:image' itemProp='image' content={image} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta property='twitter:card' content='summary_large_image' />
          </Helmet>
          <Header openForm={openForm} />
          <Main components={components} title={title}>
            {children}
          </Main>
        </div>
      </ParallaxProvider>
    )
    : null
}

export default Layout
