// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "videoHero-module--background-cover-center--37czJ";
export var backgroundCoverTop = "videoHero-module--background-cover-top--1p9FM";
export var backgroundContainTop = "videoHero-module--background-contain-top--2RWlU";
export var backgroundContainCenter = "videoHero-module--background-contain-center--2cUsG";
export var backgroundContainLeft = "videoHero-module--background-contain-left--1oqLw";
export var section = "videoHero-module--section--2jIlM";
export var mobile = "videoHero-module--mobile--1kzEq";
export var container = "videoHero-module--container--1LFr_";
export var video = "videoHero-module--video--20nPW";
export var background = "videoHero-module--background--3chX_";
export var content = "videoHero-module--content--c9i_7";
export var textContainer = "videoHero-module--text-container--3URij";
export var headingHidden = "videoHero-module--heading-hidden--2r8l5";
export var bannerText = "videoHero-module--banner-text--zs2vz";
export var line = "videoHero-module--line--3YHwL";
export var button = "videoHero-module--button--F1et9";
export var label = "videoHero-module--label--Ba7Ev";
export var noButton = "videoHero-module--no-button--kozbF";