// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "banner-module--background-cover-center--2CYj9";
export var backgroundCoverTop = "banner-module--background-cover-top--lb25l";
export var backgroundContainTop = "banner-module--background-contain-top--dGoxf";
export var backgroundContainCenter = "banner-module--background-contain-center--2B8-W";
export var backgroundContainLeft = "banner-module--background-contain-left--13jZ0";
export var section = "banner-module--section--36pNr";
export var body = "banner-module--body--36d9c";
export var label = "banner-module--label--2eh4F";
export var content = "banner-module--content--p7y-o";
export var arrow = "banner-module--arrow--3iczN";
export var orange = "banner-module--orange--14xoN";
export var animate = "banner-module--animate--19_LA";
export var fadein = "banner-module--fadein--3kJJB";