// extracted by mini-css-extract-plugin
export var section = "imagesAndCaption-module--section--39Plc";
export var top = "imagesAndCaption-module--top--i1n3N";
export var topImageContainer = "imagesAndCaption-module--top-image-container--B0qFS";
export var topImage = "imagesAndCaption-module--top-image--2iZYO";
export var bottom = "imagesAndCaption-module--bottom--9Yel4";
export var bottomImageContainer = "imagesAndCaption-module--bottom-image-container--2Eal5";
export var bottomImage = "imagesAndCaption-module--bottom-image--131RH";
export var caption = "imagesAndCaption-module--caption--3I2Fz";
export var paragraph = "imagesAndCaption-module--paragraph--3Pqos";
export var arrowContainer = "imagesAndCaption-module--arrow-container--jbTjn";
export var arrow = "imagesAndCaption-module--arrow--3yBCn";