// extracted by mini-css-extract-plugin
export var contactSection = "contact-module--contactSection--3harN";
export var image = "contact-module--image--1TcDl";
export var contactInfo = "contact-module--contact-info--15nIk";
export var heading = "contact-module--heading--3Fpo2";
export var phoneNumber = "contact-module--phone-number--1EmcC";
export var label = "contact-module--label--2Pyb2";
export var address = "contact-module--address--3hghD";
export var line = "contact-module--line--21RIk";
export var formContainer = "contact-module--form-container--39a9f";
export var form = "contact-module--form--Jz9rR";
export var field = "contact-module--field--1X0wm";
export var fieldset = "contact-module--fieldset--2bImS";
export var textarea = "contact-module--textarea--2eU17";
export var number = "contact-module--number--_0SBX";
export var fieldsetNumber = "contact-module--fieldsetNumber--3x_00";
export var button = "contact-module--button--3YooY";
export var submitted = "contact-module--submitted--2D4Jl";
export var message = "contact-module--message--kOktC";
export var footerContainer = "contact-module--footer-container--2RxkN";
export var footer = "contact-module--footer--1v6Zv";
export var copyrightContainer = "contact-module--copyright-container--2fPvy";
export var copyright = "contact-module--copyright--21fvw";
export var branding = "contact-module--branding--3LkM8";
export var span = "contact-module--span--QMsi6";
export var privacyPolicyContainer = "contact-module--privacy-policy-container--2Gc5I";
export var privacyPolicy = "contact-module--privacy-policy--30xUn";
export var socialLinks = "contact-module--social-links--8PQeb";
export var socialLink = "contact-module--social-link--1C46b";
export var icon = "contact-module--icon--26NdG";
export var footer4 = "contact-module--footer4--1ihZO";