// extracted by mini-css-extract-plugin
export var section = "imageWithHeadingAndText-module--section--n-YJZ";
export var labelContainer = "imageWithHeadingAndText-module--label-container--2IdJC";
export var label = "imageWithHeadingAndText-module--label--ZY8Rz";
export var arrowContainer = "imageWithHeadingAndText-module--arrow-container--36Twz";
export var arrow = "imageWithHeadingAndText-module--arrow--2OOTn";
export var image = "imageWithHeadingAndText-module--image--1NlsQ";
export var content = "imageWithHeadingAndText-module--content--1G2fd";
export var heading = "imageWithHeadingAndText-module--heading--yOZXr";
export var paragraph = "imageWithHeadingAndText-module--paragraph--1T_-v";
export var text = "imageWithHeadingAndText-module--text--1I3sB";