// extracted by mini-css-extract-plugin
export var section = "staffCarousel-module--section--1gqDr";
export var thumbnailsContainer = "staffCarousel-module--thumbnails-container--2XTqA";
export var label = "staffCarousel-module--label--1MSGc";
export var thumbnails = "staffCarousel-module--thumbnails--3ENJw";
export var member = "staffCarousel-module--member--I1Bal";
export var profile = "staffCarousel-module--profile--1GDRz";
export var images = "staffCarousel-module--images--3LwtP";
export var candid = "staffCarousel-module--candid--3VYBq";
export var image = "staffCarousel-module--image--22LlM";
export var name = "staffCarousel-module--name--2QxCK";
export var title = "staffCarousel-module--title--NMXua";
export var bio = "staffCarousel-module--bio--2ePKu";
export var paragraph = "staffCarousel-module--paragraph--Xr80F";
export var arrows = "staffCarousel-module--arrows--3yNm4";
export var left = "staffCarousel-module--left--iH-CA";
export var right = "staffCarousel-module--right--2p5Vd";