import React, { useState } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Fade } from 'react-reveal'
import { Parallax } from 'react-scroll-parallax'

import * as styles from './accordion.module.scss'

const Accordion = ({ component: { id, items, label } }) => {
  const [activeItem, setActiveItem] = useState(undefined)

  return (
    <section id={id} className={styles.section}>
      <div className={styles.arrowContainer}>
        <Fade down delay={1000}>
          <Parallax y={[0, 150]}>
            <div className={styles.arrow + ` background-contain-center`} />
          </Parallax>
        </Fade>
      </div>
      <Fade top>
        <h2 className={styles.label}>{label}</h2>
      </Fade>

      <Fade left cascade>
        <div className={styles.items}>
          {items && items.map((item, i) => {
            const handleSetActiveItem = () => { activeItem === i ? setActiveItem(undefined) : setActiveItem(i) }

            return (
              <div key={i} className={styles.item + `${i === activeItem ? ` ${styles.active}` : ''}`}>
                <div className={styles.header} onClick={handleSetActiveItem}>
                  <h2 className={styles.title}>{item.title}</h2>
                  <div className={styles.icon} />
                </div>
                <div className={styles.text}>
                  {item.text && parse(item.text)}
                </div>
              </div>
            )
          })}
        </div>
      </Fade>
    </section>
  )
}

export default Accordion

export const query = graphql`
  fragment Accordion on PagesJson {
    components {
      label
      items {
        title
        text
      }
    }
  }
`
