import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

type props = {
  children?: React.ReactNode | [React.ReactNode]
  className?: string
  activeClassName?: string
  onClick?: any
  page: string
  partiallyActive?: boolean
  style?: any
}

const Page = ({ children = [], className, activeClassName, partiallyActive = false, page, onClick = () => { }, style = null }: props) => {
  const { allPagesJson: { nodes: pageNodes }, allMarkdownRemark: { nodes: mdNodes } } = useStaticQuery(graphql`{
    allPagesJson {
      nodes {
        fields {
          slug
          link
        }
      }
    }
    allMarkdownRemark {
      nodes {
        fields {
          slug
        }
      }
    }
  }`)

  const node = [...pageNodes, ...mdNodes].find(node => page.includes(node.fields.slug.substr(0, node.fields.slug.length - 1)))

  return node
    ? (
      <Link style={style} to={node.fields.link} partiallyActive={partiallyActive} className={className} activeClassName={activeClassName} onClick={onClick}>
        {children}
      </Link>
    )
    : null
}

export default Page
