import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './comparison.module.scss'

const Comparison = ({ component: { id, footer } }) => {
  const { comparisonJson: { comparison } } = useStaticQuery(graphql`{
    comparisonJson(fields: {slug: {eq: "/comparison/comparison/"}}) {
      comparison {
        left
        lineText
        right
      }
    }
  }`)

  return (
    <section id={id} className={styles.section}>
      <Fade down>
        <h2 className={styles.heading}>Traditional DSOs<span className={styles.vs}>VS</span>Gen4 Dental Partners</h2>
      </Fade>
      <Fade left cascade>
        <div className={styles.table}>
          {comparison && comparison.map((comparison, i) => (
            <div key={i} className={styles.row}>
              <Fade left delay={500}>
                <div className={styles.circle + ` ${comparison.left ? `${styles.check}` : ''}`} />
              </Fade>
              <Fade up delay={500}>
                <p className={styles.copy}>{comparison.lineText}</p>
              </Fade>
              <Fade right delay={500}>
                <div className={styles.circle + ` ${comparison.right ? `${styles.check}` : ''}`} />
              </Fade>
            </div>
          ))}
        </div>
      </Fade>
      {footer ? (
        <Fade up delay={1000}>
          <p className={styles.footer}>{footer}</p>
        </Fade>
      ) : null}
    </section>
  )
}

export default Comparison

export const query = graphql`
  fragment Comparison on PagesJson {
    components {
      label
      footer
    }
  }
`
