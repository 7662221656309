import React from 'react'
import { graphql } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'
import { Fade } from 'react-reveal'

import * as styles from './imagesAndCaption.module.scss'

const ImagesAndCaption = ({ component: { id, bottomImage, caption, topImage } }) => (
  <section id={id} className={styles.section}>
    <div className={styles.arrowContainer}>
      <Fade down>
        <Parallax y={[-150, 50]}>
          <div className={styles.arrow + ` background-contain-center`} />
        </Parallax>
      </Fade>
    </div>
    <Fade right>
      <div className={styles.top}>
        <div className={styles.topImageContainer}>
          <Parallax y={[25, -25]}>
            <div className={styles.topImage + ' background-cover-center'} style={{ backgroundImage: `url(${topImage})` }} />
          </Parallax>
        </div>
      </div>
    </Fade>
    <Fade left cascade>
      <div className={styles.bottom}>
        <div className={styles.bottomImageContainer}>
          <Parallax y={[-25, 25]}>
            <div className={styles.bottomImage + ' background-cover-center'} style={{ backgroundImage: `url(${bottomImage})` }} />
          </Parallax>
        </div>
        <div className={styles.caption}>
          {caption.split('\n').map((paragraph, i) => (
            <p key={i} className={styles.paragraph}>{paragraph}</p>
          ))}
        </div>
      </div>
    </Fade>
  </section>
)

export default ImagesAndCaption

export const query = graphql`
  fragment ImagesAndCaption on PagesJson {
    components {
      topImage
      bottomImage
      caption
    }
  }
`
