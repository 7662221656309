import React from "react";
import { graphql, Link } from "gatsby";
import { Fade } from "react-reveal";
import { useState } from "react";

import * as styles from "./gen4Awards24.module.scss";

const Gen4Awards24 = ({ component: { label } }) => {
  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);
  const [showContent3, setShowContent3] = useState(false);
  const [showContent4, setShowContent4] = useState(false);

  const toggleContent = (paragraphNumber) => {
    switch (paragraphNumber) {
      case 1:
        setShowContent1(!showContent1);
        break;
      case 2:
        setShowContent2(!showContent2);
        break;
      case 3:
        setShowContent3(!showContent3);
        break;
      case 4:
        setShowContent4(!showContent4);
        break;
      // Add more cases as needed for additional paragraphs
      default:
        break;
    }
  };

  return (
    <div id={styles.awards}>
      <section className={styles.awardsContainer}>
        <Fade up delay={500}>
          <h2>Award-Winning Offices</h2>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/awards24-office2-1-min.jpg"
                alt="photo of new horizons team"
              />
            </div>
            <div className={styles.col9}>
              <h2>Office of the Year - bEAST Coast</h2>
              <h3>New Horizons Dental Care</h3>
              {/* <p style={{ marginBottom: '0px' }}><b>Salina, Kansas</b></p> */}
              <p>
              Since joining Gen4 in October 2022, New Horizons Dental Care in Salina, Kansas has achieved extraordinary results, earning the prestigious title of "Office of the Year." In 2024, faced with a shortage of doctor candidates, the team turned a challenge into an opportunity by merging Parkwood Family Dental with New Horizons to create a state-of-the-art Dental Implant Center. This expansion increased space, enhanced service offerings, broadened the patient base, and grew their overall business footprint.{" "}
                {!showContent1 && (
                  <button onClick={() => toggleContent(1)}>Read More</button>
                )}
              </p>

              {showContent1 && (
                <div>
                  <p>Over the past two years, New Horizons has achieved an impressive 25% growth, while maintaining a healthy EBITDA margin, high team retention, and a reputation for exceptional patient care. Their dedication has resulted in glowing reviews and patient referrals, showcasing their commitment to excellence.</p>
                  <p>The success of New Horizons is a testament to the team's adaptability, strategic leadership, and focus on delivering outstanding care. By balancing growth with operational excellence and a patient-first mindset, New Horizons exemplifies the values of Gen4 and serves as a model for success.</p>
                  <p>As the Office of the Year, New Horizons Dental Care demonstrates the powerful impact of teamwork, innovation, and exceptional service, setting a standard for practices across the industry.</p>
                </div>
              )}
            </div>
          </div>
        </Fade>

        <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/awards24-office1-1-min.jpg"
                alt="photo of Nelson & Tillmann family dentistry team"
              />
            </div>
            <div className={styles.col9}>
              <h2>Office of the Year - Ascenders</h2>

              <h3>Nelson & Tillmann Family Dentistry</h3>
              
              <p>
              This exceptional team in Murray, Utah has demonstrated outstanding performance, surpassing their revenue goal by 7.2% and exceeding their EBITDA target by 10.8% YTD. Their impressive 6.5% year-over-year growth in revenue highlights their dedication, consistent hard work, and focus on achieving their goals.{" "}
                {!showContent2 && (
                  <button onClick={() => toggleContent(2)}>Read More</button>
                )}
              </p>
              <p>
                {showContent2 && (
                  <div>
                    <p>Beyond the numbers, Wasatch Dental-Murray radiates a positive energy that's reflected in their strong OfficeVibe scores. Whether it's their teamwork, enthusiasm, or perhaps the occasional Crumbl Cookie, this team consistently creates a high-performing and enjoyable environment.</p>
                    <p>Wasatch Dental-Murray embodies what it means to excel, and they are truly deserving of the Office of the Year title.</p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </Fade>

        {/* <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/location-beaumont-min.jpg"
                alt="photo of beaumont family dentistry office"
              />
            </div>
            <div className={styles.col9}>
              <h2>Office of the Year - Growth</h2>

              <h3>Beaumont Family Dentistry - Leestown</h3>
              <p>
                Beaumont's impressive trajectory in growth and development has
                rightfully earned them the Gen4 LeGEND Award for Office of the
                Year for Growth. This prestigious recognition reflects their
                extraordinary success in expanding their patient base, enhancing
                service offerings, and increasing their overall business
                footprint.{" "}
                {!showContent3 && (
                  <button onClick={() => toggleContent(3)}>Read More</button>
                )}
              </p>
              {showContent3 && (
                <div>
                  <p>
                    The team atBeaumont has exhibited a remarkable ability to
                    not only attract new patients but also retain existing ones
                    by providing exceptional dental care and creating a
                    welcoming, trust-inspiring environment.
                  </p>
                  <p>
                    Their strategic marketing efforts, coupled with a strong
                    focus on community engagement, have played a pivotal role in
                    their growth. Furthermore, the office has embraced
                    innovation, incorporating advanced dental technologies and
                    broadening their range of services to meet diverse patient
                    needs. This approach has not only improved patient outcomes
                    and satisfaction but has also positioned Beaumont as a
                    leader in the dental field. The leadership's vision for
                    growth, supported by the dedicated efforts of their team,
                    has led to notable increases in both revenue and reputation.
                  </p>
                  <p>
                    Their success story is a testament to the power of combining
                    excellent patient care with strategic business acumen,
                    making Beaumont an exemplary recipient of the Office of the
                    Year for Growth award.
                  </p>
                </div>
              )}
            </div>
          </div>
        </Fade> */}

        {/* <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img
                src="/location-gentling-min.jpg"
                alt="photo of cassity implants"
              />
            </div>

            <div className={styles.col9}>
              <h2>Office of the Year - Ops Excellence</h2>
              <h3 className={styles.doctorName}>Gentling Dental Care</h3>

              <p>
                Gentling's exceptional performance in operational excellence has
                earned it the prestigious Gen4 LeGEND Award for Office of the
                Year. This accolade is a testament to their outstanding
                efficiency, seamless patient care processes, and exemplary
                administrative management.{" "}
                {!showContent4 && (
                  <button onClick={() => toggleContent(4)}>Read More</button>
                )}
              </p>
              <p>
                {showContent4 && (
                  <div>
                    <p>
                      The team at Gentling Dental Care has consistently
                      demonstrated a commitment to optimizing every aspect of
                      their operations, from streamlined appointment scheduling
                      and effective patient communication to advanced technology
                      utilization and meticulous record-keeping.
                    </p>
                    <p>
                      Their focus on operational excellence has not only
                      enhanced the patient experience but also improved the
                      workflow for the staff, creating a harmonious and
                      productive work environment. The leadership at Gentling
                      Dental Care has been instrumental in implementing
                      innovative strategies and best practices that drive
                      efficiency and quality care. Their ability to adapt to
                      changing circumstances, while maintaining high standards
                      of service, sets them apart in the dental industry. The
                      team's collaborative efforts, attention to detail, and
                      dedication to continuous improvement have resulted in
                      remarkable operational success, making Gentling Dental
                      Care a model of excellence in dental office management.
                    </p>
                    <p>
                      Their achievement in maintaining such high operational
                      standards makes them a deserving recipient of the Office
                      of the Year for Operational Excellence award, highlighting
                      their role as a leader in setting benchmarks for the
                      industry.
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </Fade> */}

        {/* <Fade up delay={500}>
          <div className={styles.awardRow}>
            <div className={styles.col3}>
              <img src="/team-logan-c.jpg" alt="photo of cassity implants" />
            </div>

            <div className={styles.col9}>
              <h2>Pathway to Partnership Doctor of The Year</h2>
              <h3 className={styles.doctorName}>Dr. Logan Clements</h3>
              <h4>Community Shores Dental | Michigan</h4>
              <p>
                Gentling's exceptional performance in operational excellence has
                earned it the prestigious Gen4 LeGEND Award for Office of the
                Year. This accolade is a testament to their outstanding
                efficiency, seamless patient care processes, and exemplary
                administrative management. <a href="#">Read More</a>
              </p>
            </div>
          </div>
        </Fade> */}
      </section>
    </div>
  );
};

export default Gen4Awards24;

export const query = graphql`
  fragment Gen4Awards24 on PagesJson {
    components {
      label
    }
  }
`;
