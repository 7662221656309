// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "overview-module--background-cover-center--34n7V";
export var backgroundCoverTop = "overview-module--background-cover-top--2-Xpp";
export var backgroundContainTop = "overview-module--background-contain-top---Nze-";
export var backgroundContainCenter = "overview-module--background-contain-center--LNeUt";
export var backgroundContainLeft = "overview-module--background-contain-left--oc9Ap";
export var section = "overview-module--section--2s8U2";
export var body = "overview-module--body--3dhIJ";
export var content = "overview-module--content--sMIC6";
export var heading = "overview-module--heading--a30GW";
export var arrow = "overview-module--arrow--1PJX2";
export var orange = "overview-module--orange--11Wdk";
export var animate = "overview-module--animate--3Smtq";
export var fadein = "overview-module--fadein--3RvlI";