import React from 'react'
import { graphql, Link } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './textAndButton.module.scss'

const TextAndButton = ({ component: { button, id, text } }) => (
  <section id={id} className={styles.section}>

    {button && button.display && !button.new_tab
      ? (
        <Fade down delay={500}>
          <Link className={styles.button} to={button.link}>
            <Fade down delay={500}>
              <p className={styles.text}>{text}</p>
            </Fade>
            <div className={styles.line} />
            <Fade right cascade delay={500}>
              <p className={styles.label}>{button.label}</p>
            </Fade>
          </Link>
        </Fade>
      )
      : button && button.display && button.new_tab ? (
        <Fade down delay={500}>
          <a className={styles.button} href={button.link} rel='noopener noreferrer' target="_blank">
            <Fade down delay={500}>
              <p className={styles.text}>{text}</p>
            </Fade>
            <div className={styles.line} />
            <Fade right cascade delay={500}>
              <p className={styles.label}>{button.label}</p>
            </Fade>
          </a>
        </Fade>
      ) : (
        <Fade down delay={500}>
          <p className={styles.text}>{text}</p>
        </Fade>
      )}
  </section>
)

export default TextAndButton

export const query = graphql`
  fragment TextAndButton on PagesJson {
    components {
      text
      button {
        display
        label
        link
        new_tab
      }
    }
  }
`
