// extracted by mini-css-extract-plugin
export var backgroundCoverCenter = "heroWithLogo24-module--background-cover-center--1g9HW";
export var backgroundCoverTop = "heroWithLogo24-module--background-cover-top--8OAWM";
export var backgroundContainTop = "heroWithLogo24-module--background-contain-top--1Fn2n";
export var backgroundContainCenter = "heroWithLogo24-module--background-contain-center--3glqe";
export var backgroundContainLeft = "heroWithLogo24-module--background-contain-left--3bI-F";
export var section = "heroWithLogo24-module--section--bRx_4";
export var background = "heroWithLogo24-module--background--2CI7r";
export var content = "heroWithLogo24-module--content--1iL_l";
export var fill = "heroWithLogo24-module--fill--1iNeh";
export var logo = "heroWithLogo24-module--logo--qnV1d";
export var yearText = "heroWithLogo24-module--year-text--kHFaX";
export var textContainer = "heroWithLogo24-module--text-container--31h1V";
export var textDiv = "heroWithLogo24-module--text-div--1DWbW";
export var bannerText = "heroWithLogo24-module--banner-text--51H9g";
export var line = "heroWithLogo24-module--line--1D33T";
export var countdownContainer = "heroWithLogo24-module--countdown-container--E5oC_";
export var countdownLabel = "heroWithLogo24-module--countdown-label--1RpBj";
export var countdownTo = "heroWithLogo24-module--countdown-to--3EqKK";