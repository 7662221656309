import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'
import parse from 'html-react-parser'

import * as styles from './banner.module.scss'

const Banner = ({ component: { content, heading, id } }) => (
  <section id={id} className={styles.section}>
    <div className={styles.body}>
      {heading && <h2 className={styles.label}>{heading}</h2>}
      {content && (
        <Fade left cascade>
          <p className={styles.content}>{parse(content)}</p>
        </Fade>
      )}
    </div>
  </section>
)

export default Banner

export const query = graphql`
  fragment Banner on PagesJson {
    components {
      label
      heading
      content
    }
  }
`
