import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'
import parse from 'html-react-parser'

import * as styles from './textAndImage.module.scss'

const TextAndImage = ({ component: { id, text, image } }) => (
  <div className={styles.section} id={id}>
    <Fade down>
      <div className={styles.image + ' background-cover-center'} style={{ backgroundImage: `url(${image})` }} />
    </Fade>
    <Fade up cascade>
      <div className={styles.right}>
        {text && parse(text)}
      </div>
    </Fade>
  </div>
)

export default TextAndImage

export const query = graphql`
  fragment TextAndImage on PagesJson {
    components {
      text
      image
    }
  }
`
